import React, { useEffect, useState } from 'react';
import axios from 'axios';
import NavigationBar from './NavigationBar';
import { useAppContext } from './AppContext';
import CartItem from './CartItem';
import PayPal from './PayPal';
import './check_out.css';
import { FaTimes } from 'react-icons/fa';
import PaymentTelebirr from './PaymentTelebirr';


// require('dotenv').config();

// const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
const API_URL = 'https://api-netconn.brosfe.com';


function Checkout() {
  const { currentLanguage, setPage, cartItems, setCartItems, orderComplete, setOrderComplete } = useAppContext();

  useEffect(() => {
    setPage('Checkout');
  }, []);

  const [paymentDetails, setPaymentDetails] = useState({
    cardNumber: '',
    expiryDate: '',
    cvv: '',
  });

  // Function to clear the cart
  const clearCart = () => {
    setCartItems([]);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [isPaidLoaded, setIsPaidLoaded] = useState(false);

  const handleInputChange = (e) => {
    setPaymentDetails({
      ...paymentDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validation check: ensure card number is not empty
    if (!paymentDetails.cardNumber) {
      setError('Please enter your card number.');
      return;
    }
    setIsSubmitting(true);
    setError('');

    try {
      // Calculate the total price
      const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

      // Create the order object
      const order = {
        user: e.target.id.value,
        items: cartItems,
        totalPrice,
        paymentDetails,
      };

      // Send the order data to the server
      const response = await axios.post(`${API_URL}/api/orders`, order);

      // Handle the successful order submission
      console.log('Order submitted successfully:', response.data);
      // You can redirect the user to a success page or display a success message
    } catch (error) {
      console.error('Error submitting order:', error);
      setError('An error occurred while submitting the order.');
    } finally {
      setIsSubmitting(false);
    }
  };





  return (

    <div className='main-container'>
      {currentLanguage === 'English' && (
        <div className='sub-container'>
          <NavigationBar />
          <CartItem cartItems={cartItems} />

          <h2>Checkout</h2>
          {error && <div className="error-message">{error}</div>}
          {orderComplete && (
            <div className="order-complete-message">
              <p>Thank you for your order!</p>
              <p>Your order number is: blabla</p>
              <button onClick={() => {
                setOrderComplete(false);
                clearCart();
              }}
              >
                <FaTimes />
              </button>
            </div>
          )}

          <div className='checkout-field'>

            <div>
              <form onSubmit={handleSubmit}>

                <label htmlFor="cardNumber">Card Number</label>
                <input
                  type="text"
                  id="cardNumber"
                  name="cardNumber"
                  value={paymentDetails.cardNumber}
                  onChange={handleInputChange}
                />
              </form>
            </div>
            <PayPal />
            <PaymentTelebirr />

          </div>
        </div>
      )}
      {currentLanguage === 'አማርኛ' && (
        <div className='sub-container'>
          <NavigationBar />

          <div className="Motto">
            <h1>ይህ የኮምፖነንት አምሳያ ገጽ ነው፡፡</h1>
          </div>
        </div>
      )}
    </div>

  );
}

export default Checkout;