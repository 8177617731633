import * as React from "react"
import { SVGProps } from "react"
import { delay, easeIn, motion } from "framer-motion"
import { opacityProperty, scale } from "kute.js";
const SvgAboutBgr = (props: SVGProps<SVGSVGElement>) => {

    const pathRotate = (index: number) => ({
        hidden: {
          opacity:1,
          scale: 1,
          rotate: 0,
      
        },
        visible: {
              
          rotate: 360 * index,
          opacity: 1,
          scale: 1,
      
          transition: {
            duration: 3,
            ease: 'easeInOut',
            delay: index * 0.25,
            repeat: Infinity,
            repeatType: 'reverse',
            repeatDelay: 0.5, // Add a delay between color changes
          },
        },
      });

      const pathElevate = {
        hidden: {
          opacity:1,
          scale: 0.8,
        //   y: 0,
      
        },
        visible: {
              
         
          opacity: 1,
          scale: 0.4,
        //   y: '-100px',
      
          transition: {
            duration: 3,
            ease: 'easeInOut',
            delay: 0.25,
            repeat: Infinity,
            repeatType: 'reverse',
            repeatDelay: 0.5, // Add a delay between color changes
          },
        },
      };
    return(
  <svg
    id="visual"
    viewBox="0 0 960 600"
    // width={960}
    // height={600}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}

    style={{position: 'absolute', top: '0%', left: '0%', width: '100%', height: '100%',
    margin:'auto', zIndex:'0'
    }}
  >
    <rect width={960} height={600} fill="none" />
    <motion.g>
      <motion.g transform="translate(665 448)"
            >
        <motion.path
          d="M0 -145.8L114 -90.9L142.1 32.4L63.3 131.4L-63.3 131.4L-142.1 32.4L-114 -90.9Z"
          fill="#020202"
          variants={pathElevate}
          initial="hidden"
          animate="visible"
        />
      </motion.g>
      <motion.g transform="translate(263 237)"
      >
        <motion.path
          d="M0 -47L36.7 -29.3L45.8 10.5L20.4 42.3L-20.4 42.3L-45.8 10.5L-36.7 -29.3Z"
          fill="#020202"
          variants={pathRotate(1)}
          initial="hidden"
          animate="visible"
        />
      </motion.g>
    </motion.g>
  </svg>
)
}
export default SvgAboutBgr 
