import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';


import NavigationBar from './NavigationBar';
import { useAppContext } from './AppContext';
import axios from 'axios';

import CartItem from './CartItem';

import './product_details.css';

import introAd1 from './assets/images/ads/intro/introAdSample1.png'
import introAd2 from './assets/images/ads/intro/introAdSample2.png'
import introAd3 from './assets/images/ads/intro/introAdSample3.png'

// const API_URL = process.env.REACT_APP_API_URL || '';
const API_URL = 'https://api-netconn.brosfe.com';
const ProductDetails = () => {

    const navigate = useNavigate();

   
    const { currentLanguage, setPage, cartItems, addToCart  } = useAppContext();
    const [pageId, setPageId] = useState(null);
    const zoomFactor = 2; // Set the zoom factor
    const [zoomBoxSize, setZoomBoxSize] = useState(50);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
    const [zoomedImagePosition, setZoomedImagePosition] = useState({ x: 0, y: 0 });
    const [zoomBoxVisible, setZoomBoxVisible] = useState(false);
    
    


    const { id } = useParams();

    const [product, setProduct] = useState(null);

    const [mainImage, setMainImage] = useState('');
    const [displayedImage, setDisplayedImage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [checkoutError, setCheckoutError] = useState('');

    const additionalImages = [
        mainImage,
        introAd1,
        introAd2,
        introAd3
    ];

    useEffect(() => {

        setPage(`Products/Services/${id}`);
        setPageId(id);

    }, [pageId]);


    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(`${API_URL}/api/products/${id}`);

                setProduct(response.data);
                setMainImage(response.data.image);
                setDisplayedImage(response.data.image);
               
            } catch (err) {
                setError('Error fetching product details');
            } finally {
                setIsLoading(false);
            }
        };

        fetchProductDetails();
    }, [id]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!product) {
        return <div>Product not found</div>;
    };



    // Calculate the position of the zoom box around the mouse cursor
    const handleMouseMove = (e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        // Calculate dynamic zoom box size
        const imageWidth = e.currentTarget.width;
        const imageHeight = e.currentTarget.height;
        const BoxSize = Math.min(imageWidth / zoomFactor, imageHeight / (2 * zoomFactor));
        setZoomBoxSize(BoxSize);

        // Calculate zoom box position based on mouse position
        const zoomBoxX = x - (zoomBoxSize / 2);
        const zoomBoxY = y - (zoomBoxSize / 2);
        setImagePosition({ x: zoomBoxX, y: zoomBoxY });

        const left = -(x * zoomFactor - zoomBoxSize / 2);
        const top = -(y * zoomFactor - zoomBoxSize / 2);
        setZoomedImagePosition({ x: left, y: top });


        // Set the mouse position
        setMousePosition({ x, y });
        setZoomBoxVisible(true);
    };

    const handleMouseLeave = () => {
        setImagePosition({ x: 0, y: 0 });
        setMousePosition({ x: 0, y: 0 });
        setZoomBoxVisible(false);
    };

    const handleAddToCart = () => {
        const newCartItem = {
          id: product._id,
          name: product.name,
          price: product.price.current,
          image: product.image,
        };
        addToCart(newCartItem);
      };

      const handleCheckout = () => {
        
        if (cartItems.length === 0) {
          setCheckoutError('Please add some items to your cart before checking out.');
          return;
        }
        navigate('/cart');
        
      };

    return (
        <div className="product-details-container">
            {currentLanguage === 'English' && (
                <div className="sub-container">
                   
                    <NavigationBar />
                    <CartItem cartItems={cartItems} />
                    <div className="product-details">
                        <div className="additional-images">
                            {additionalImages.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`Additional image ${index}`}
                                    onMouseEnter={() => setDisplayedImage(image)}
                                    onClick={() => setDisplayedImage(image)}
                                />
                            ))}
                        </div>

                        <div
                            className="main-image"
                        // onMouseMove={handleMouseMove}
                        // onMouseLeave={handleMouseLeave}
                        >
                            {mainImage ? (
                                <img src={displayedImage} alt="Main product image"
                                    onMouseMove={handleMouseMove}
                                    onMouseLeave={handleMouseLeave} />
                            ) : (
                                <div>Loading...</div>
                            )}

                            {/* Render the zoom box if it's visible */}
                            {zoomBoxVisible && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        left: `${imagePosition.x}px`, // Set left position
                                        top: `${imagePosition.y}px`, // Set top position
                                        width: `${zoomBoxSize}px`, // Use the dynamic zoom box size
                                        height: `${zoomBoxSize}px`, // Use the dynamic zoom box size
                                        backgroundColor: 'rgba(0, 0, 0, 0.3)', // Low opacity background color
                                        // border: '2px solid red', // Add a red border for visibility
                                        boxSizing: 'border-box', // Ensures padding and border are within the dimensions
                                        pointerEvents: 'none', // Allow mouse events to pass through
                                    }}

                                />
                            )}
                        </div>

                        <div className="product-details-info">
                            <h4>{product.name}</h4>
                            <div className='price-container'>
                            <p className='price'>{product.price.current}ETB
                            </p><span>{product.price.past[0].price}ETB</span>
                            </div>
                          

                           
                            <p className='description'>{product.description}</p>
                            <p>{product.features}</p>
                            
                            {mousePosition.x !== 0 && mousePosition.y !== 0 && (
                                <div
                                    className="zoomed-image"
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        overflow: 'hidden',
                                        border: 'none',
                                    }}
                                >
                                    <img
                                        src={displayedImage}
                                        alt="Zoomed Image"
                                        style={{
                                            position: 'absolute',
                                            left: `-${imagePosition.x}px`,
                                            top: `-${imagePosition.y}px`,
                                            width: `${displayedImage.width * zoomFactor}px`,
                                            height: `${displayedImage.height * zoomFactor}px`,
                                            transform: `scale(${zoomFactor})`,
                                        }}
                                    />
                                </div>
                            )}

                            <div className="button-cta-cart-container">
                            <button onClick={handleAddToCart}>Add to Cart</button>
                            <button onClick={handleCheckout}>Checkout</button>
                            {checkoutError && <p className='error-message'>{checkoutError}</p>}
                            </div>

                           
                        </div>
                    </div>
                </div>
            )}

            {currentLanguage === 'አማርኛ' && (
                <div className="sub-container">
                    <NavigationBar />
                    <div className="product-details"></div>
                </div>
            )}
        </div>
    );
}

export default ProductDetails;