import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import './policies.css'; // Import CSS file for styling
import { useAppContext } from './AppContext';

import companyPolicies from './companyPolicies';
import NavigationBar from './NavigationBar';
import Footer from './Footer';

import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';


const Policies = () => {
    const { currentLanguage, setPage } = useAppContext();
    useEffect(() => {
        setPage('policies');
    }, []);



    //   const PolicySection = ({ key, section }) => (
    //     <div id={section.id} key={key}>
    //       <h2>{section.heading}</h2>
    //       {Array.isArray(section.content) ? (
    //         <ul>
    //           {section.content.map((item, index) => (
    //             <li key={index}>{item}</li>
    //           ))}
    //         </ul>
    //       ) : (
    //         <p>{section.content}</p>
    //       )}
    //     </div>
    //   );

    const PolicySection = ({ section }) => (
        <div className='policy-section'
            id={section.id}>
            <h4>{section.heading}</h4>
            {Array.isArray(section.content) ? (
                <ul>
                    {section.content.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            ) : (
                <p>{section.content}</p>
            )}
        </div>
    );


    const PolicyPage = ({ policy }) => {
        const location = useLocation();

        useEffect(() => {
            if (location.hash) {
                const element = document.getElementById(location.hash.substring(1));
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }, [location]);

        return (
            <section id={policy.id}>
                <h2>{policy.title}</h2>
                {policy.sections.map((section, index) => (
                    <PolicySection key={index} section={section} />
                ))}
            </section>
        );
    };


    return (
        <motion.div

            initial={{ opacity: 0 }} // Initial position before movement animation
            animate={{ opacity: 1, }} // Move to the left corner of the screen
            transition={{ duration: 2, ease: 'linear' }} // Delay movement animation by 1 second
            style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}
        >
            {currentLanguage === 'English' && (
                <div className='main-container'>
                    <NavigationBar />
                    <div className='sub-container'
                        style={{
                            backgroundColor: '#fff',
                            width: '80%', margin: 'auto',
                            marginBottom:'32px'
                        }}>

                        <div className='policies-container'>

                            <PolicyPage policy={companyPolicies.codeOfConduct} />


                            <PolicyPage policy={companyPolicies.cancellationAndRefundPolicy} />





                        </div>

                    </div>
                    <Footer />
                </div>
            )}
            {currentLanguage === 'አማርኛ' && (
                <div className='sub-container'>
                    <NavigationBar />
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h1>መነሻ ገጽ</h1>
                    </div>
                </div>
            )}
        </motion.div>
    );
};

export default Policies;