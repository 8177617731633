import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import './about-pitch.css'; // Import CSS file for styling
import { useAppContext } from './AppContext.js';

import mission from './assets/images/image-collages/mission.png';
import vision from './assets/images/image-collages/vision.png';
import goals from './assets/images/image-collages/goals.png';
import services from './assets/images/image-collages/services.png';




import { MissionSvgComponent } from './MissionSvgComponent.tsx';
import CustomWave from './CustomWave.js';
import CustomWaveBottom from './CustomWaveBottom.js';
import SvgMorph from './svgComponents/SvgMorph.js';
import SvgMorph2 from './svgComponents/SvgMorph2.js';
import SvgMorphMain from './svgComponents/SvgMorphMain.js';
import { scale } from 'kute.js';
import CubeIntro from './CubeIntro2.js';




const AboutPitch = () => {
    const { currentLanguage, currentPage } = useAppContext();


    const { ref, inView } = useInView({
        triggerOnce: false,
        rootMargin: '-100px',
    });

    const pitchVariants = {
        hidden: { opacity: 0, y: 200, scale: 0.8 },
        visible: {
            opacity: 1,
            y: 0,
            scale: 1,
            transition: {
                duration: 0.8,
                ease: 'easeInOut',
            },
        },
    };

    const childVariants = {
        hidden: { x: 0 },
        visible: {
            y: -50,
            x: [0, (i) => (i % 2 === 0 ? -100 : 100), 0],
            transition: {
                delay: 0.2,
                duration: 0.8,
                ease: 'easeInOut',
                times: [0, 0.5, 1], // Add times array to control x animation
            },
        },
    };

    const pitchContent =[
        {
            src: mission, alt: 'company mission', title: 'MISSION',
            description: 'At NetConn Engineering, we believe in growing organically through careful planning and the application of sound business principles. Our mission is to continually push boundaries, as we understand that the only way to discover the limits of the possible is to go beyond the impossible.'
        },
        {
            src: vision, alt: 'company vision', title: 'VISION',
            description: 'At NetConn Engineering, we envision a future where technology is seamlessly integrated into every aspect of business, enhancing efficiency, fostering innovation, and driving sustainable growth. Our vision is to be a catalyst for digital transformation, empowering organizations to navigate the complexities of tomorrow with confidence and resilience. Through cutting-edge solutions and unwavering dedication, we strive to shape a connected world where possibilities are boundless, and success is determined by the limitless potential of technology.'

        },
        {
            src: goals, alt: 'company goals', title: 'GOAL',
            description: 'Our goal is to deliver technology solutions that provide lasting strategic value to your enterprise. By collaborating with the right partners, we ensure deployments use the right products, future-proofing involves the right architecture, and implementation follows our best practices.'
        },
        {
            src: services, alt: 'company services', title: 'SERVICES',
            description: 'Our core activities encompass a diverse range, including computer systems, peripherals, accessories and consumables, software solutions, system integration, office automation systems, networking solutions, communication products, hardware maintenance, training, and business alliances.'
        },
    ];


    useEffect(() => {
        const pitchContent =[
            {
                src: mission, alt: 'company mission', title: 'MISSION',
                description: 'At NetConn Engineering, we believe in growing organically through careful planning and the application of sound business principles. Our mission is to continually push boundaries, as we understand that the only way to discover the limits of the possible is to go beyond the impossible.'
            },
            {
                src: vision, alt: 'company vision', title: 'VISION',
                description: 'At NetConn Engineering, we envision a future where technology is seamlessly integrated into every aspect of business, enhancing efficiency, fostering innovation, and driving sustainable growth. Our vision is to be a catalyst for digital transformation, empowering organizations to navigate the complexities of tomorrow with confidence and resilience. Through cutting-edge solutions and unwavering dedication, we strive to shape a connected world where possibilities are boundless, and success is determined by the limitless potential of technology.'
    
            },
            {
                src: goals, alt: 'company goals', title: 'GOAL',
                description: 'Our goal is to deliver technology solutions that provide lasting strategic value to your enterprise. By collaborating with the right partners, we ensure deployments use the right products, future-proofing involves the right architecture, and implementation follows our best practices.'
            },
            {
                src: services, alt: 'company services', title: 'SERVICES',
                description: 'Our core activities encompass a diverse range, including computer systems, peripherals, accessories and consumables, software solutions, system integration, office automation systems, networking solutions, communication products, hardware maintenance, training, and business alliances.'
            },
        ];

    }, []);    





    return (
        < >
            {currentLanguage === 'English' && (

                <div className='sub-container'
                    style={{
                        backgroundColor: '#fff',
                        padding: '0px',


                    }}>
                    <CustomWave fillColor={'#5733FF'} waveHeight={'10rem'} />

<h1><span style={{color:'#5733FF'}}>OUR PRINCIPLES</span><br /><span style={{color:'#020202'}}><span style={{fontSize:'8rem'}}>.</span>OUR OFFERINGS</span></h1>


                    <div className='pitch-container'>
                        <CubeIntro />
                    </div>


                    <CustomWaveBottom fillColor={'#eee'} waveHeight={'7.5rem'} />
                </div>

            )}
            {currentLanguage === 'አማርኛ' && (
                <div className='sub-container'>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h1>መነሻ ገጽ</h1>
                    </div>
                </div>
            )}
        </>
    );
};

export default AboutPitch;