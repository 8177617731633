import React from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { FaFacebook, FaYoutube, FaTwitter, FaPhone, FaEnvelope, FaTelegram } from 'react-icons/fa';
import './footer.css';

import { useAppContext } from './AppContext';
import CustomWave from './CustomWave';


const Footer = () => {
    const { currentLanguage } = useAppContext();

    const navigate = useNavigate();

    const handleCategoryClick = (category) => {
        navigate('/products-services', { state: { category } });
    };

    const telegramAccount = process.env.REACT_APP_TELEGRAM_ACCOUNT;
    const xAccount = process.env.REACT_APP_X_ACCOUNT;
    const facebookAccount = process.env.REACT_APP_FACEBOOK_ACCOUNT;
    const youtubeAccount = process.env.REACT_APP_YOUTUBE_ACCOUNT;
    const phoneNumber = process.env.REACT_APP_PHONE_NUMBER;


    return (
        < >
            {currentLanguage === 'English' && (

                <div className='sub-container'
                    style={{ width: '100%', backgroundColor: '#fff', color: '#5733FF', padding: '0px' }}

                >


                    <footer className="footer">
                        <div className="footer-container">
                            <div className="footer-column">
                                <h4>Company</h4>
                                <ul>
                                    <li>
                                        <Link to="/about">About</Link>
                                    </li>

                                    <li><Link to="/policies#code-of-conduct">Code of Conduct</Link></li>
                                    <li><Link to="/policies#cancellation-refund-policy">Cancellation & Refund Policy</Link></li>
                                </ul>
                            </div>

                            {/* <div className="footer-column">
                                <h4>Products</h4>
                                <ul>
                                    <li><a href="#" onClick={() => handleCategoryClick('speed')}>Computers &amp; Laptops</a></li>
                                    <li><a href="#" onClick={() => handleCategoryClick('Simens')}>Network Devices</a></li>
                                    <li><a href="#" onClick={() => handleCategoryClick('printer')}>Computer Accessories</a></li>
                                </ul>
                            </div> */}
                            <div className="footer-column">
                                <h4>Resources</h4>
                                <ul>
                                    <li>
                                        <Link to="/blog">Blog</Link>
                                    </li>

                                </ul>
                            </div>
                            <div className="footer-column">
                                <h4>Social</h4>
                                <ul className="social-icons">
                                    <li>
                                        <a
                                            href={`https://t.me/${telegramAccount}`}
                                            target="_blank" rel="noopener noreferrer">
                                            <FaTelegram />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={`https://www.facebook.com/${facebookAccount}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FaFacebook />
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a
                                            href={`https://www.youtube.com/${youtubeAccount}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FaYoutube />
                                        </a>
                                    </li> */}
                                    <li>
                                        <a
                                            href={`https://www.twitter.com/${xAccount}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FaTwitter />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-column">
                                <h4>Address</h4>
                                {/* <address>
                                Bole Road Bahir Bldg 1st Floor 1-7, in front of Getu Commercial<br />
                                    Tel: +251-911-106380 <br />+251-911-781965 <br />+251-116-6385570<br />
                                    Email: netconneng@gmail.com
                                </address> */}
                                <ul>
                                    <li>Bole Road Bahir Bldg 1st Floor office 1-7 | <br />in front of Getu Commercial</li>
                                    <li className='icon'>
                                        <a href={`tel:${phoneNumber}`} target="_blank" rel="noopener noreferrer"><FaPhone /></a>

                                    </li>
                                    <li>+251-911-106380 | +251-911-781965 | +251-116-6385570</li>
                                    {/* <li><FaEnvelope /> </li>
                                    <li>netconneng@gmail.com</li> */}
                                    <li className='icon'><a href="mailto:netconneng@gmail.com" target="_blank" rel="noopener noreferrer"><FaEnvelope /></a>netconneng@gmail.com</li>




                                </ul>
                            </div>
                        </div>
                        <div className="footer-bottom">
                            <p>&copy; {new Date().getFullYear()} netconn engineering. All rights reserved.</p>
                        </div>

                    </footer>
                </div>

            )}
            {currentLanguage === 'አማርኛ' && (
                <div className='sub-container'>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h1>መነሻ ገጽ</h1>
                    </div>
                </div>
            )}
        </>
    );
};

export default Footer;