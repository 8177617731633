import React, { useState, useEffect } from 'react';
import NavigationBar from './NavigationBar';
import { useAppContext } from './AppContext';
import './contact-us.css';
import ContactPoint from './ContactPoint';
import Footer from './Footer';
import logo from './assets/images/logo/netconn_engineering-compact.png'

function ContactUs() {
  const { currentLanguage, setPage } = useAppContext();
  useEffect(() => {
    setPage('Contact Us');
}, []);

  const contactSettings = {
    backgroundColor: '#eee',
    header: 'We love to hear from you.',
    description: 'Let\'s drive innovation together.',
  }
  return (
    <div className='main-container'>
      {currentLanguage === 'English' && (
      <div className='sub-container'>
        
          <NavigationBar />

          <div className='motto-contactus'>
            <img src={logo} alt='logo' />
            <p >
              At netconn Engineering, we strive to provide the highest quality
              products and services. Our team of experts has years of
              experience in the field. We are committed to providing you with
              the best possible customer experience.
            </p>
            <h4>Abraham Tsegaye, CEO</h4>
          </div>
        
          <ContactPoint backgroundColor = {contactSettings.backgroundColor} header={contactSettings.header} 
                    description={contactSettings.description}/>
           <Footer />
      </div>

     
     
    )}
    {currentLanguage === 'አማርኛ' && (
      <div className='sub-container'>
        <NavigationBar />
        
        <div className="Motto">
          <h1>ይህ የኮምፖነንት አምሳያ ገጽ ነው፡፡</h1>
        </div>
      </div>
      
     
    )}
      
    </div>
    
  );
}

export default ContactUs;
