import React, { useEffect, useState } from 'react';
import './landing_page.css'; // Import CSS file for styling
import { motion } from 'framer-motion';
import { SvgComputerMouse } from './svgComponents/SvgComputerMouse.tsx';

const LandingPage = () => {
    const isMobile = window.innerWidth <= 430; // Example breakpoint for mobile devices

    // Define box sizes based on device type
    const boxSize = isMobile ? 100 : 150; 
    
    // Calculate the number of rows and columns based on screen size
    const numRows = Math.ceil(window.innerHeight / boxSize); // Assuming box height is 200px
    const numCols = Math.ceil(window.innerWidth / boxSize); // Assuming box width is 200px


    const [classMap, setClassMap] = useState({}); // State to hold the class names for each box

    // UseEffect hook to initialize class names for each box
    useEffect(() => {
        const initialClassMap = {};
        for (let i = 0; i < numRows * numCols; i++) {
            initialClassMap[i] = i % 2 === 0 ? 'even' : 'odd'; // Initialize class names based on index
        }
        setClassMap(initialClassMap);
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            setClassMap(prevClassMap => {
                // Create a copy of the previous class map
                const newClassMap = { ...prevClassMap };

                // Toggle the class names for each box
                Object.keys(newClassMap).forEach(key => {
                    newClassMap[key] = newClassMap[key] === 'even' ? 'odd' : 'even';
                });

                return newClassMap;
            });
        }, 1000); // Toggle class names every second

        return () => clearInterval(interval); // Clean up the interval when the component unmounts
    }, []);


    // Generate an array of box elements
    const renderBoxes = () => {
        const boxes = [];
        for (let i = 0; i < numRows * numCols; i++) {

            const className = `box ${classMap[i] || 'even'}`; // Use class name from state or default to 'even'
            boxes.push(<div key={i} className={className}></div>);
        }
        return boxes;
    };

    return (
        <motion.div className="landing-page"
            style={{ overflow: 'hidden' }}>
           
            <motion.div className="landing-text"
             initial={{ opacity: 1}}
             animate={{ opacity: 0 }}
             transition={{ duration: 0.5, delay:3, ease: [0.87, 0, 0.13, 1]}}

            >netconn engineering</motion.div>
            {renderBoxes()}
            <motion.div className='mouse-container'
            >
                <SvgComputerMouse />
            </motion.div>
            {/* <motion.div
                       
    initial={{ opacity: 0}} // Initial opacity is 0 and background color is white
    animate={{ opacity: 1}} // Opacity animates to 1 and background color changes to red
    transition={{ duration: 1, delay: 4 }} // Animation duration is 1 second, with a delay of 4 seconds
>
                <button className="enter-button"
                    style={{
                        writingMode: 'vertical-rl',
                        textOrientation: 'upright', // Ensure text orientation is upright
                        whiteSpace: 'nowrap', // Prevent text from wrapping
                        width: 'auto', // Adjust width as needed
                    }}
                ><span>ENTER</span>
                </button>

            </motion.div> */}
        </motion.div>
    );
};

export default LandingPage;
