import * as React from "react"
import { SVGProps } from "react"
import { delay, easeIn, motion } from "framer-motion"
import { rotate, scale } from "kute.js"
const SvgLoading = (props: SVGProps<SVGSVGElement>) => {

   

   

      const groupVariants = ()=> ({
        hidden: {
          opacity: 1,
          rotate: 0,
        
        },
        visible: {
          opacity: 1,
          rotate: 360,
          transition: {
            duration: 1,
            ease: 'easeInOut',
            delay: 3,
            repeat: Infinity,
            repeatType: 'reverse',
            repeatDelay: 4.5, // Add a delay between color changes
          },
        },
      });

      const rectangleVariants = (index: number) => ({
        hidden: {
          opacity: 1,
          scale: 1,
          rotate: 0,
          
          fill: '#5733FF',
          stroke: '#5733FF',
        },
        visible: {
          opacity: 1,
          scale: 2,
          rotate: 360 * index,
          fill: ['#5733FF', '#020202', '#3399cc'], 
          stroke: ['#5733FF', '#020202', '#3399cc'], 
          transition: {
            duration: 1,
            ease: 'easeInOut',
            delay: index * 0.25,
            repeat: Infinity,
            repeatType: 'reverse',
            repeatDelay: 0.5, // Add a delay between color changes
          },
        },
      });


 
    return (
        <svg
            // width="165.09999mm"
            // height="118.00416mm"
            viewBox="0 0 165.09999 118.00416"
            id="svg5"
            xmlns="http://www.w3.org/2000/svg"
            // xmlns:svg="http://www.w3.org/2000/svg"
            {...props}
            style={{ width: '100%', height: '100%',
            //  border: '1px solid red',
             display: 'flex', justifyContent: 'center', alignItems: 'center',
             margin:'auto',
            //  backgroundColor:'#FFD9E8'
             }}
        >
         
            <motion.g id="layer1" 
            transform="translate(-73.904528,-87.469292)"
            >
                <motion.g id="g94769">
                    <motion.g id="g88159"
                    //  transform="translate(0.37417734,12.72203)" 
                    />
                    <motion.g id="g97419">
                      
                        <motion.g id="g57011" 
                        // transform="translate(-11.796655,11.399114)"
                        >
                           
                              
                            <motion.g id="g57002"
                            variants={groupVariants}
                            initial="hidden"
                            animate="visible"
                            style={{border: '1px solid red'}}>
                                <motion.path
                                    id="rect8863"
                                    style={{ strokeWidth: 0.288472 }}
                                    d="m 121.32341,110 h 65.72507 v 8.0874 h -65.72507 z"
                                    variants={rectangleVariants(1)}
                                    initial="hidden"
                                    animate="visible"
                                />
                                <motion.path
                                    id="rect11769"
                                    style={{ strokeWidth: 0.288472 }}
                                    d="m 121.41695,132 h 65.72507 v 8.0874 h -65.72507 z"
                                    variants={rectangleVariants(2)}
                                    initial="hidden"
                                    animate="visible"
                                />
                                <motion.path
                                    id="rect11771"
                                    style={{ strokeWidth: 0.288472 }}
                                    d="m 121.60403,154 h 65.72507 v 8.0874 h -65.72507 z"
                                    variants={rectangleVariants(3)}
                                    initial="hidden"
                                    animate="visible"
                                />
                            </motion.g>
                        </motion.g>
                       
                    </motion.g>
                </motion.g>
            </motion.g>
        </svg>
    )
}
export default SvgLoading
