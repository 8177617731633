
// NavigationBar.js
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation, useNavigation, } from 'react-router-dom';

import { useAppContext } from './AppContext';

import logo from './assets/images/logo/netconn_engineering-compact.png';
import LanguageSelector from './LanguageSelector';
import './navigation_bar.css';
import { color, motion, AnimatePresence } from "framer-motion";
import { FaSearch } from 'react-icons/fa';
import SvgCompanyLogo from './svgComponents/SvgCompanyLogo.tsx';

const NavigationBar = () => {
  const navigate = useNavigate();
  const { currentLanguage, currentPage } = useAppContext();
  const [isScrolled, setIsScrolled] = useState(0);
  const [isScrolledPortfolio, setIsScrolledPortfolio] = useState(0);
  const largeScale = 4.9;
  const smallScale = 0.8;
  const largeScalePortfolio = 3;
  const smallScalePortfolio = 1.8;

  useEffect(() => {
    const handleScroll = () => {
      const scrolledPixels = window.scrollY;

      // Check for the first state variable (isScrolled)
      const isScrolledValue = scrolledPixels >= largeScale * window.innerHeight
        ? 2
        : scrolledPixels > smallScale * window.innerHeight
          ? 1
          : 0;

      // Check for the second state variable (isScrolledPortfolio)
      const isScrolledPortfolioValue = (scrolledPixels >= smallScalePortfolio * window.innerHeight) &&
        (scrolledPixels < largeScalePortfolio * window.innerHeight)
        ? 2
        : 0;


      // Set the state variable based on your naming convention
      setIsScrolled(isScrolledValue);
      setIsScrolledPortfolio(isScrolledPortfolioValue);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getHeaderStyles = ({ currentPage }) => {
    // #5353f663
    return currentPage === "Products/Services" ? {
      // position: 'static', 
      position: 'static',
      top: '12px',
      left: 0,



    } : {

      position: 'static',
      top: '12px',
      left: 0,
      color: '#FFFFFF',
      backgroundColor: 'transparent',

    }
  }
  const getNavStyles = ({ currentPage }) => ({
    // color: currentPage === "LandingPage" ? '#FFFFFF' : '#FFFFFF',

    // Define styles for hover and active states
    // '&:hover': {
    //   color: '#020202', // Customize hover color
    // },
    // '&:active': {
    //   color: currentPage === "LandingPage" ? '#3C1A99' : '#5733FF', // Customize active color
    // }
  });



  const headerStyle = {

    zIndex: 1000,
    ...getHeaderStyles({ currentPage }),



  };
  const navStyle = {


    ...getNavStyles({ currentPage }),



  };

  const navigationItemsEnglish = [
    {
      id: 1,
      name: 'Home',
      path: '/home',
    },
    // {
    //   id: 2,
    //   name: 'Products|Services',
    //   path: '/products-services',
    // },
    {
      id: 2,
      name: 'Software',
      // path: '/software',
      path: 'https://software.netconnengineering.com',
      external: true  // Add this flag
    },
    {
      id: 3,
      name: 'About',
      path: '/about',
    },
    {
      id: 4,
      name: 'Contact Us',
      path: '/contactus',
    },
    // {
    //   id: 4,
    //   name: 'Blog',
    //   path: '/blog',
    // },

  ]
  const navigationItemsAmharic = [
    {
      id: 1,
      name: 'መነሻ',
      path: '/home',
    },
    // {
    //   id: 2,
    //   name: 'ምርቶች/አገልግሎቶች',
    //   path: '/products-services',
    // },
    {
      id: 3,
      name: 'ስለኛ',
      path: '/about',
    },
    {
      id: 4,
      name: 'ያግኙን',
      path: '/contactus',
    },
    // {
    //   id: 4,
    //   name: 'ብሎግ',
    //   path: '/blog',
    // },

  ];




  const selectedNavigationItems = currentLanguage === 'English' ? navigationItemsEnglish : navigationItemsAmharic;

  const [hoveredStates, setHoveredStates] = useState(
    selectedNavigationItems.map(() => false)
  );

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 600 && menuOpen) {
        // Reset the state if the screen size is greater than 600px and menuOpen is true
        setMenuOpen(false);
      }

    };

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [menuOpen]);

  useEffect(() => {

    const handleOutsideClick = (e) => {

      if (!e.target.closest('.search-input') && !e.target.closest('.search-navbar')) {
        if (menuOpen && !e.target.closest('.hamburger-menu') &&
          !e.target.closest('.nav-links')
        ) {
          const navLinks = document.querySelector('.nav-links');
          navLinks.classList.remove('show');
          setTimeout(() => {

            setMenuOpen(!menuOpen);
          }, 1000);
        }

      }

    };
    const handleNavLinkClick = (e) => {
      if (!e.target.closest('.search-input') && !e.target.closest('.search-navbar')) {
        if (menuOpen && e.target.closest('.nav-links')) {
          const navLinks = document.querySelector('.nav-links');
          navLinks.classList.remove('show');
          setTimeout(() => {

            setMenuOpen(!menuOpen);
          }, 700);
        }

      }


    };


    document.body.addEventListener('click', handleOutsideClick);
    document.querySelector('.nav-links').addEventListener('click', handleNavLinkClick);

    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, [menuOpen, setMenuOpen]);

  const handleGetInTouch = () => {
    navigate('/contactus')
  }

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight * 5) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`navigation-bar-container ${isSticky ? 'sticky-nav' : ''}`} >
      {/* <div className="navigation-bar-container" > */}

      <div className='header'
        style={headerStyle}>

        <div className="Web-logo"
          key="logo">
          <NavLink to="/" exact="true">
            <SvgCompanyLogo />
          </NavLink>
        </div>


        <div className='header-nav'
          key={`headernav-${currentLanguage}`}>
          <div className='navigation-container'>


            <div className={`nav-links ${menuOpen ? 'show' : ''}`}>
              {selectedNavigationItems.map((item, index) => (
                <div key={`${item.id}-${item.name}`} className='nav-item-container'>
                  {item.external ? (
                    <a
                      href={item.path}
                      className='nav-item'
                      style={navStyle}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.name}
                    </a>
                  ) : (
                    <NavLink to={item.path} className='nav-item' style={navStyle}>
                      {item.name}
                    </NavLink>
                  )}
                </div>
              ))}

              {/* <div className='search-navbar'>
                <label>
                  <FaSearch />
                </label>
                <input
                  type="text"
                  placeholder="Find products you like .."
                  className="search-input"

                >

                </input>
              </div> */}

              <div className='quick-links'>
                <div className='get-in-touch'>
                  <button onClick={handleGetInTouch}>Get In Touch</button>
                </div>
                {/* <div id='language-selector'>
                  <LanguageSelector />
                </div> */}
              </div>


            </div>



            <div className='hamburger-menu' onClick={toggleMenu} >
              {menuOpen ? '✕' : '☰'}
            </div>
          </div>
        </div>



      </div>


    </div>

  );
}

export default NavigationBar;

