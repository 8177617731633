import React from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import './cart_item.css';

const CartItem = ({ cartItems }) => {

  const navigate = useNavigate();

  const handleProceedToCheckout = () => {
    navigate('/cart');
  };

  const totalItems = cartItems.reduce((total, item) => total + item.quantity, 0);
  const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

  return (
    <div className="cart-item" onClick={handleProceedToCheckout}>
  
       <span><FaShoppingCart /> {totalItems}</span>
   
    </div>
  );
};

export default CartItem;