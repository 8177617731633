import React, { useEffect, useState } from 'react';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useAppContext } from './AppContext';

const PayPal = () => {
  const { cartItems,setOrderComplete } = useAppContext();
  const [totalPrice, setTotalPrice] = useState(0);
  const [paymentDetails, setPaymentDetails] = useState({
    cardNumber: "",
    expiryDate: "",
    cvv: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  
  const paypalClientID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

  useEffect(() => {
    // const totalPrice = 10;
    // setTotalPrice(totalPrice);
    let totalPrice = 0;
    cartItems.map((item) => {
      totalPrice += item.price * item.quantity;
    });
    setTotalPrice(totalPrice);
  }, []);

  const handleInputChange = (e) => {
    setPaymentDetails({
      ...paymentDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    // ... (existing handleSubmit function)
  };

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: totalPrice.toString(),
          },
        },
      ],
    });
  };

 

  const onApprove = async (data, actions) => {
    try {
      const order = await actions.order.capture();
      console.log("Order captured:", order);
      setOrderComplete(true);
      // Handle successful payment here
    } catch (error) {
      console.error("Error capturing order:", error);
      setError("An error occurred while processing the payment.");
    }
  };

  return (
    <PayPalScriptProvider
      options={{
        "client-id": paypalClientID,
        currency: "USD",
      }}
    >
      <div>
        <PayPalButtons
          createOrder={(data, actions) => createOrder(data, actions)}
          onApprove={(data, actions) => onApprove(data, actions)}
        />
      </div>
      <div>
      <PayPalLoadingStatus />
      </div>
    </PayPalScriptProvider>
  );
};

const PayPalLoadingStatus = () => {
  const [{ isLoaded, payPalError }] = usePayPalScriptReducer();

  return (
    <>
      {payPalError && <div>{payPalError}</div>}
      {!isLoaded ? null : <div>Loading PayPal...</div>}
    </>
  );
};

export default PayPal;
