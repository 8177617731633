import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';


import logo from './logo.png';
import './App.css';
import Home from './Home';
import ProductPage from './ProductPage';
import ProductDetails from './ProductDetails';
import About from './About';
import ContactUs from './ContactUs';
import Blog from './Blog';
import AdminDashboard from './AdminDashboard';
import ScrollReveal from './ScrollReveal';
import Cart from './Cart';
import Checkout from './Checkout';
import Policies from './Policies';



import { AppProvider } from './AppContext';
import { AnimatePresence } from 'framer-motion';

function App() {

  const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return null;
};


    // Redirect to subdomain if the path is /software
    useEffect(() => {
      if (window.location.pathname === '/software') {
          window.location.href = 'https://software-netconnengineering.onrender.com';
      }
  }, []);


  return (

    
    <AnimatePresence mode='concurrent'>
    <Router>
      <ScrollToTop/>
      <AppProvider>

        <Routes >
          

          <Route path="/" element={<ScrollReveal />} />
          <Route path='/home' element={<Home />} />
          <Route path='/products-services/:id' element={<ProductDetails />} />
          <Route path='/products-services' element={<ProductPage />} />
          <Route path='/about' element={<About />} />
          <Route path='/contactus' element={<ContactUs />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/checkout' element={<Checkout />} />
          <Route path='/policies' element={<Policies />} />
          <Route path='/admin' element={<AdminDashboard />} />
        </Routes>
      </AppProvider>
    </Router>
  </AnimatePresence>


  );
}

export default App;
