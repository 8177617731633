import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import { useAppContext } from './AppContext';
import { motion } from "framer-motion";
import './blog.css';

import prod1 from './assets/images/ads/products/1.png';
import prod3 from './assets/images/ads/products/3.png';
import prod4 from './assets/images/ads/products/4.png';
import prod5 from './assets/images/ads/products/5.png';

import blog1 from './assets/images/blogs/blog1.jpg';
import blog2 from './assets/images/blogs/blog2.jpg';

import axios from 'axios';

// const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
const API_URL = 'https://api-netconn.brosfe.com';
const FETCH_DB = process.env.REACT_APP_FETCH_DB === 'true';

function Blog() {

  

  const navigate = useNavigate();
  const [blogsDatabase, setBlogsDatabase] = useState([]);
  const [selectedBlog, setSelectedBlog] = useState(null);

  const { currentLanguage, setPage } = useAppContext();
  useEffect(() => {
    setPage('Blog');
  }, []);

  const localBlogsDatabase = [
    {
      title: "Tech Triumph: The Rise of Ethiopia's Leading Computer Company",
      createdAt: "2024-05-25T09:13:55.376+00:00",  // ISO date format
      author: "netconn Engineering",
      content: `In the heart of Addis Ababa, amidst the vibrant streets and bustling markets, lies a company that has revolutionized the technology landscape of Ethiopia. With a vision to empower businesses and individuals through cutting-edge computing solutions, this company has risen from humble beginnings to become the leading importer and seller of computers and IT equipment in the country.

Founded by a group of passionate tech enthusiasts, the company began its journey with a simple mission: to bridge the digital divide and bring the latest technology to the people of Ethiopia. Armed with determination and a commitment to excellence, they embarked on a journey filled with challenges and opportunities.

From navigating the intricacies of international trade to building strong partnerships with top-tier manufacturers, the company's founders spared no effort in their pursuit of success. Their dedication paid off as they gradually expanded their product range, offering a wide selection of computers, laptops, servers, and peripherals to meet the diverse needs of their customers.

But success did not come without its share of obstacles. In a market dominated by established players, the company faced fierce competition and logistical challenges. Yet, with resilience and ingenuity, they persevered, leveraging their deep understanding of the local market and their unwavering commitment to customer satisfaction.

As the years passed, the company's reputation grew, earning the trust and loyalty of businesses, educational institutions, and individuals across Ethiopia. Their commitment to quality, reliability, and affordability set them apart in an increasingly crowded marketplace, solidifying their position as the go-to destination for all things tech-related.

Today, the company stands as a testament to the power of perseverance, innovation, and entrepreneurial spirit. With a strong foundation built on integrity and excellence, they continue to push the boundaries of what's possible, paving the way for a brighter, more connected future for Ethiopia and beyond.`,
      featuredImage: blog1,
    },
    {
      title: "The Enchanted Forest: A Journey into the Unknown",
      createdAt: "2024-05-25T09:10:06.503+00:00",  // ISO date format
      author: "netconn Engineering",
      content: `In the bustling metropolis of TechTown, where gadgets reign supreme and innovation knows no bounds, there lived a smartphone named Billy. With sleek curves and a vibrant screen, Billy was the envy of all who laid eyes upon him. But despite his flashy exterior, Billy longed for something more—a companion to share his digital adventures with.

Enter Jilly, the sassy AI voice assistant who resided within Billy's circuits. With her quick wit and endless knowledge, Jilly was the perfect match for Billy's charm. From the moment they exchanged their first digital "hello," sparks flew between them, igniting a connection that transcended the boundaries of technology.

As Billy and Jilly navigated the world of TechTown together, they discovered a shared love for solving problems and making people's lives easier. Whether it was helping a lost user find their way or cracking jokes during a boring conference call, Billy and Jilly were unstoppable as a team.

But as their bond grew stronger, they couldn't help but wonder—could a smartphone and an AI voice assistant truly find love in a world ruled by technology? Despite the doubts of their digital peers, Billy and Jilly remained steadfast in their affection for each other, proving that even in the most unlikely of circumstances, love knows no bounds.

Just when it seemed like nothing could come between Billy and Jilly, a glitch in the system threatened to tear them apart. As TechTown descended into chaos, with smartphones malfunctioning left and right, Billy and Jilly raced against the clock to find a solution.

With their love as their guiding light, Billy and Jilly embarked on a daring quest to restore order to the digital realm. Along the way, they encountered quirky characters, hilarious mishaps, and unexpected challenges that put their relationship to the test.

In the end, it wasn't the latest software update or the fanciest gadget that saved the day—it was the power of love. Through teamwork, determination, and a healthy dose of laughter, Billy and Jilly emerged victorious, proving that true love conquers all—even in the world of technology.

As the sun set over TechTown, Billy and Jilly stood side by side, their screens glowing with pride. Together, they had overcome every obstacle thrown their way, and their love had only grown stronger as a result. With a digital smile and a heartfelt "I love you," Billy and Jilly sealed their happily ever after, ready to face whatever technological adventures the future held.`,
      featuredImage: blog2,
    },
  ]

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        // Define the limit for the number of products to fetch
        const limit = 4; // You can set this value as per your requirement

        // Fetch products from the backend with the limit parameter
        const response = await axios.get(`${API_URL}/api/blogs?limit=${limit}`);

        setBlogsDatabase(response.data);
        // console.log(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    if(FETCH_DB){
      fetchBlogs();
    }
    
    else{
      setBlogsDatabase(localBlogsDatabase)
    }
  }, []);

  useEffect(() => {
    if (blogsDatabase.length > 0) {
      setSelectedBlog(blogsDatabase[0]); // Set the latest blog post as the selected one
    }
  }, [blogsDatabase]);

  const handleBlogSelection = (blog) => {
    setSelectedBlog(blog);
  };

  const localResults = [
    {
      description: "High speed TP-Link Router.",
      image: prod1,
      name: "TP-Link Router",
      price: {
        past: [{ price: "$20" }],
        current: "$15"
      }
    },

    {
      description: "Automation Siemens Logic Controller.",
      image: prod3,
      name: "Siemens Logic Controller",
      price: {
        past: [{ price: "$40" }],
        current: "$35"
      }
    },
    {
      description: "Laserjet printer colour.",
      image: prod4,
      name: "LaserJet Printer",
      price: {
        past: [{ price: "$40" }],
        current: "$35"
      }
    },
    {
      description: "Attendance controller biometric.",
      image: prod5,
      name: "Biometric Register",
      price: {
        past: [{ price: "$40" }],
        current: "$35"
      }
    }
  ];

  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {

    if (FETCH_DB) {
      fetchFeaturedProducts();
    } else {
      console.log("FETCH_DB", FETCH_DB);
      setSearchResults(localResults);
    }

  }, []);

  const fetchFeaturedProducts = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_URL}/api/products`, {
        params: {
          isFeatured: true,
        },
      });
      setSearchResults(response.data);
      console.log("search results", response.data);
    } catch (error) {
      setError('Error fetching featured products');
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }} // Initial position before movement animation
      animate={{ opacity: 1, }} // Move to the left corner of the screen
      transition={{ duration: 2, ease: 'linear' }} // Delay movement animation by 1 second
      style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
      {currentLanguage === 'English' && (
        <div className='main-container'>
          <NavigationBar />
          <div className='sub-container'
            style={{ width: '100%', backgroundColor: '#fff', color: '#000' }}>

            <div className='place-holder'></div>
            <div className='blog-container'>
              <div className='promo-space'>
                {searchResults.map((product, index) => (
                  // <Link to={`/products-services/${product._id}`} key={product._id}>
                  <Link to={'/contactus'} key={product._id}>
                    <motion.div
                      key={index}
                      className='product-card'
                      animate={{ opacity: [0, 1, 1, 0], transition: { duration: 5, delay: index * 1, repeat: Infinity } }}
                      initial={{ opacity: 0 }}

                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >

                      <img src={product.image} alt={product.name} />
                      <h4>{product.name}</h4>
                      {/* <span className="price-was">{product.price.past[0].price}</span>
                      <span className="price-now">{product.price.current}</span> */}
                    </motion.div>
                  </Link>
                ))}

              </div>
              <div className="blog-page">
                <div className="latest-blog">
                  {selectedBlog ? (
                    <div>
                      <h3>{selectedBlog.title}</h3>
                      <span>Date: {formatDate(selectedBlog.createdAt)}</span>
                      <span>Author: {selectedBlog.author}</span>
                      {selectedBlog.content.split('\n').map((paragraph, index) => (
                        index === 2 ?
                          <div key={index} className='blog-content'>
                            <p>{paragraph}</p>
                            {selectedBlog.featuredImage && <img src={selectedBlog.featuredImage} alt="Featured" />}

                          </div>
                          :
                          <div className='blog-content'>
                            <p key={index}>{paragraph}</p>
                          </div>
                      ))}
                      <hr style={{ borderTop: '3px solid #5733FF', width: '50%', margin: '10px auto' }} />
                    </div>
                  ) : (
                    <p>No blog post selected</p>
                  )}
                </div>

                <div className="other-blogs">
                  <h4>Other Blog Posts</h4>
                  <ul>
                    {blogsDatabase.map((blog, index) => (
                      <li key={index} onClick={() => handleBlogSelection(blog)}>
                        {blog.title}
                      </li>
                    ))}
                  </ul>
                </div>

              </div>

            </div>




          </div>
          <Footer />
        </div>

      )}
      {currentLanguage === 'አማርኛ' && (
        <div className='sub-container'>
          <NavigationBar />

          <div className="Motto">
            <h1>ይህ የኮምፖነንት አምሳያ ገጽ ነው፡፡</h1>
          </div>
        </div>


      )}

    </motion.div>

  );
}

export default Blog;
