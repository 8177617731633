import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import './cubeIntro2.css'; // Import CSS file for styling
import NavigationBar from './NavigationBar';
import { useAppContext } from './AppContext';
import { FaPlay, FaPause } from 'react-icons/fa';



import mission from './assets/images/image-collages/mission.png';
import vision from './assets/images/image-collages/vision.png';
import goals from './assets/images/image-collages/goals.png';
import services from './assets/images/image-collages/services.png';
import logo from './assets/images/logo/netconn_engineering-compact.png'

const Cube = ({ scrollEnd, onScrollEndChange }) => {
    const { currentLanguage, setPage } = useAppContext();

    const [rotation, setRotation] = useState({ rotateY: 0, rotateX: 0, rotateZ: 0 });
    const [isAnimating, setIsAnimating] = useState(true);
    const [buttonPressed, setButtonPressed] = useState(false);
    const controls = useAnimation();



    const handleAnimationAction = () => {
        setTimeout(() => {
            onScrollEndChange(!scrollEnd); // Toggle the value of scrollEnd

        }, 1000);

    };

   


    const pitchContent = [
        {
            src: mission, alt: 'company mission', title: 'MISSION',
            description: 'At NetConn Engineering, we believe in growing organically through careful planning and the application of sound business principles. Our mission is to continually push boundaries, as we understand that the only way to discover the limits of the possible is to go beyond the impossible.',
            classname: 'front',
        },
        {
            src: vision, alt: 'company vision', title: 'VISION',
            description: 'At NetConn Engineering, we envision a future where technology is seamlessly integrated into every aspect of business, enhancing efficiency, fostering innovation, and driving sustainable growth. Our vision is to be a catalyst for digital transformation, empowering organizations to navigate the complexities of tomorrow with confidence and resilience. Through cutting-edge solutions and unwavering dedication, we strive to shape a connected world where possibilities are boundless, and success is determined by the limitless potential of technology.',
            classname: 'back',

        },
        {
            src: logo, alt: 'company goals', title: '',
            description: '',
            classname: 'right',
        },
        {
            src: services, alt: 'company services', title: 'SERVICES',
            description: 'Our core activities encompass a diverse range, including computer systems, peripherals, accessories and consumables, software solutions, system integration, office automation systems, networking solutions, communication products, hardware maintenance, training, and business alliances.',
            classname: 'left',
        },
        {
            src: goals, alt: 'company goals', title: 'GOAL',
            description: 'Our goal is to deliver technology solutions that provide lasting strategic value to your enterprise. By collaborating with the right partners, we ensure deployments use the right products, future-proofing involves the right architecture, and implementation follows our best practices.',
            classname: 'top',
        },
        {
            src: logo, alt: 'betconn', title: '',
            description: '',
            classname: 'bottom',

        },
    ];


    const [displayedItem, setDisplayedItem] = useState(pitchContent[0]);

    const startAnimation = () => {
        controls.start({
            rotateX: [rotation.rotateX, rotation.rotateX + 360],
            rotateY: [rotation.rotateY, rotation.rotateY + 180],
            rotateZ: [rotation.rotateZ, rotation.rotateZ + 360],
            transition: {
                duration: 10,
                ease: 'linear',
                repeat: Infinity,  // Ensure continuous animation
                repeatType: 'reverse',  // Loop the animation
            }

        });
    };



    const stopAnimation = () => {

        controls.stop();
    };

    useEffect(() => {
        if (isAnimating) {
            startAnimation();
        } else {
            stopAnimation();
        }
    }, [isAnimating, controls, rotation]);


    const handleCubeRotation = () => {
        setButtonPressed(!buttonPressed);
        setIsAnimating(!isAnimating);


    };

   
    const getSideInView = (rotateX, rotateY, rotateZ) => {
        const rotations = [
            { side: 'front', rotateX: 0, rotateY: 0, rotateZ: 0 },
            { side: 'back', rotateX: 0, rotateY: 180, rotateZ: 0 },
            //   { side: 'right', rotateX: 0, rotateY: 270, rotateZ: 0 },
            { side: 'left', rotateX: 0, rotateY: 90, rotateZ: 0 },
            { side: 'top', rotateX: 90, rotateY: 0, rotateZ: 0 },
            //   { side: 'bottom', rotateX: 90, rotateY: 180, rotateZ: 0 },
        ];

        // const threshold = 180;

        const closestSide = rotations.reduce((closest, side) => {
            const xDiff = Math.abs(side.rotateX - rotateX);
            const yDiff = Math.abs(side.rotateY - rotateY);
            const zDiff = Math.abs(side.rotateZ - rotateZ);
            const totalDiff = xDiff + yDiff + zDiff;

            if (totalDiff < closest.diff) {
                return { side: side.side, diff: totalDiff };
            }

            return closest;
        }, { side: null, diff: Infinity });

        return closestSide.side;
    };


    return (
        <div className='cube-about-container'>
            <motion.div
            // animate={controls}
            // initial={{ rotateY: 0, rotateX: 0, rotateZ: 0, scale: 0.5 }}


            >
                <motion.div
                    className="cube-about"
                    animate={controls}
                    initial={{ rotateY: 0, rotateX: 0, rotateZ: 0, scale: 0.5 }}
                    onUpdate={(latest) => {
                        if (buttonPressed) {
                            setRotation({ rotateY: latest.rotateY, rotateX: latest.rotateX, rotateZ: latest.rotateZ });
                        }
                        const sideInView = getSideInView(
                            latest.rotateX,
                            latest.rotateY,
                            latest.rotateZ
                        );
                        const item = pitchContent.find((item) => item.classname === sideInView);
                        setDisplayedItem(item || null);

                    }}

                >




                    {pitchContent.map((item, index) => (
                        <motion.div
                            className={`cube-about-item ${item.classname}`}
                            key={`cube-about-item-${index}`}
                            custom={index}
                        >
                            <>
                                {item.title.length > 0 ? (
                                    <>
                                        <motion.div className="cube-about-content">
                                            <p>{item.title}</p>
                                            <p>{item.description}</p>
                                        </motion.div>
                                        <motion.div className="cube-about-content">
                                            <img src={item.src} alt={item.alt} />
                                        </motion.div>
                                    </>
                                ) : (
                                    <motion.div className="cube-about-content">
                                        <img src={item.src} alt={item.alt} />
                                    </motion.div>
                                )}
                            </>
                        </motion.div>
                    ))}


                </motion.div>
            </motion.div>

            {displayedItem && (
                <div className="displayed-item-details">
                    {displayedItem.title.length > 0 ? (
                        <>
                            <h3>{displayedItem.title}</h3>
                            <p>{displayedItem.description}</p>
                            <div className='image-container'>
                            <img src={displayedItem.src} alt={displayedItem.alt} />

                            </div>
                           
                        </>
                    ) : (
                        <img src={displayedItem.src} alt={displayedItem.alt} />
                    )}
                </div>
            )}

            <div className="cube-about-button-container">

                <button onClick={handleCubeRotation}>
                    {isAnimating ? <FaPause /> : <FaPlay />}
                </button>

            </div>







        </div>
    );
};

export default Cube;
