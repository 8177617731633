import React, { useEffect, useState, useRef } from 'react';
import './testimonies.css'; // Import CSS file for styling
import { useAppContext } from './AppContext';

import axios from 'axios';

import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';

import CustomWave from './CustomWave.js';
import SvgLoading from './svgComponents/SvgLoading.tsx';

// const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
const API_URL = 'https://api-netconn.brosfe.com';
const FETCH_DB = process.env.REACT_APP_FETCH_DB === 'true';

const Testimonies = () => {
    const { currentLanguage } = useAppContext();

    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [totalTestimonyItems, setTotalTestimonyItems] = useState(0);
    const localResults = [
        {
            quote: "Netconn Engineering carry out their commitments with us with the required standard and in a timely manner.",
            name: "Yared Seyoum, ICAP"
        },
        {
            quote: "The company supplies the items we request on time, with efficiently and reliability. We recommend Netconn Engineering for your future businesses.",
            name: "Wossen Belete, World Vision"
        },
        {
            quote: "We're in business with Netconn for over a decade due to their high quality IT equipment and computer accessory supplies with huge discounts.",
            name: "Gert Kluge, NiRAS"
        }
    ];
    

    
    useEffect(() => {
        if(FETCH_DB){
            fetchTestimonies();
        }else{
            setSearchResults(localResults);
        }
      
    }, []);

    useEffect(() => {
        setTotalTestimonyItems(searchResults.length);
        console.log('Testimonies fetched:', totalTestimonyItems);
    }, [searchResults]);


    const fetchTestimonies = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${API_URL}/api/testimonies`);
            if (response.data) {
                console.log('Testimonies fetched:', response.data);

            }
            if(response.data.length > 0) {
                console.log("Testimonies.. There is some response data.");
            }
            setSearchResults(response.data);


        } catch (error) {
            setError('Error fetching company staff');
        } finally {
            setIsLoading(false);
        }
    };
    const containerVariants = {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 1, // Delay between each child animation
            },
        },
    };

    const testimonyVariants = {
        initial: {
            x: '100%',
        },
        animate: {
            x: '-200%',
            transition: {
                ease: 'linear',
                duration: 20, // Adjust duration as needed
                repeat: Infinity,
            },
        },
    };


    const containerRef = useRef(null);

    const [offset, setOffset] = useState(0);
    const testimonyWidth = 30; // Adjust width as needed

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setOffset(prevOffset => prevOffset - 1);
    //     }, 50); // Adjust interval as needed

    //     return () => clearInterval(interval);
    // }, []);

    // useEffect(() => {
    //     const containerWidth = containerRef.current.offsetWidth;
    //     const testimonyWidth = (containerWidth / searchResults.length) * 100; // Convert to percentage

    //     const interval = setInterval(() => {
    //       containerRef.current.style.transform = `translateX(-${testimonyWidth}%)`;

    //       setTimeout(() => {
    //         containerRef.current.style.transition = 'none';
    //         containerRef.current.style.transform = 'translateX(0)';
    //         setTimeout(() => {
    //           containerRef.current.style.transition = '';
    //         }, 50);
    //       }, 500);
    //     }, 3000); // Adjust interval duration as needed

    //     return () => clearInterval(interval);
    //   }, [searchResults]);

    const calculateAnimationDelay = (numTestimonies, index) => {
        const animationDuration = 30; // in seconds
        const delay = (animationDuration / numTestimonies) * (numTestimonies - index - 1) * -1;
        return delay;
    };

    const calculateLeftPosition = (numTestimonies, index) => {
        const itemWidth = 25; // assuming each item is 20% wide
        const maxLeft = Math.max(itemWidth * numTestimonies, 100);
        const left = maxLeft - itemWidth * (index + 1);
        // return left;
        return maxLeft;
    };

    return (
        < >
            {currentLanguage === 'English' && (

                <div className='sub-container'
                    style={{
                        backgroundColor: '#020202',
                        color: 'white', paddingBottom: '48px'
                    }}>
                    <CustomWave fillColor='#5733FF' waveHeight={'9rem'} />

                    <h2>WHAT OUR CLIENTS TELL ABOUT US</h2>


                    <motion.div className="testimonies-wrapper">
                        {searchResults &&
                            searchResults.map((testimony, index) => (
                                <motion.div
                                    key={index}
                                    className={`testimony-item`}
                                    style={{
                                        animationDelay: `${calculateAnimationDelay(searchResults.length, index)}s`,
                                        left: `${calculateLeftPosition(searchResults.length, index)}%`,

                                    }}
                                >
                                    <FaQuoteLeft className="quote-icon left" />
                                    <p>{testimony.quote}</p>
                                    <span>{testimony.name}</span>
                                    <FaQuoteRight className="quote-icon right" />
                                </motion.div>


                            ))}

                        {isLoading &&

                            <div className="loader-testimony">

                                <SvgLoading />
                            </div>
                        }
                    </motion.div>

                </div>


            )}
            {currentLanguage === 'አማርኛ' && (
                <div className='sub-container'>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h1>መነሻ ገጽ</h1>
                    </div>
                </div>
            )}
        </>
    );
};

export default Testimonies;