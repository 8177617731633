import React, { useEffect, useRef } from "react";

import { motion, useAnimation, useInView } from "framer-motion"
import { rotate } from "kute.js";
const SvgTextMotto: React.FC = () => {

  const controls = React.useRef(null);
  const anotherControls = useAnimation();
  const isInView = useInView(controls, { once: false });



  const letterVariants = {
    initial: { opacity: 1, y: 0, scale: 1, rotate: 0 },
    animate: (i: number) => ({
      opacity: 1,
      y: 0,
      scale: 1,
      rotate: 0,
      fill: ['#000', '#FFFFFF'], // Example color sequence
      transition: {
        delay: i * 0.12,
        duration: 0.36,
        type: 'spring',
        fill: {
          delay: i * 0.12,
          duration: 0.6, // Adjust duration for the color transition
          loop: Infinity,
        },
      },
    }),
  };

  const bonusVariants = {
    initial: { opacity: 1, y: 0, scale: 1, rotate: 0 },
    animate: (i: number) => ({
      opacity: 1,
      y: 0,
      scale: 1,
      rotate: 3600,
      fill: ['#000', '#FFFFFF'], // Example color sequence
      transition: {
        delay: 0,
        duration: 0.36,
        type: 'spring',
        rotate: {
          delay: i * 0.06,
          duration: 6, // Adjust duration for the color transition
          loop: Infinity,
        },
        fill: {
          delay: i * 0.12,
          duration: 0.6, // Adjust duration for the color transition
          loop: Infinity,
        },
      },
    }),

  }

  const combinedVariants = {
    initial: { opacity: 1, y: 0, scale: 1, rotate: 0 },
    animate: (i: number) => ({
      opacity: 1,
      y: 0,
      scale: 1.1,
      rotate: 3600,
      stroke: ["#020202", '#ffffff'],
      fill: ['#000', '#FFFFFF'],
      transition: {
        delay: 0,
        duration: 0.36,
        type: 'spring',
        rotate: {
          delay: i * 0.06,
          duration: 6,
          loop: Infinity,
        },
        fill: {
          delay: i * 0.12,
          duration: 0.6,
          loop: Infinity,
        },
        stroke: {
          duration: 6,
          
        }
      },
    }),
    scaleHorizontal: {
      scaleX: [1, 2, 1],
      transition: {
        duration: 1.2,
        repeat: 5,
        ease: 'easeInOut',
      },
    },
    scaleBack: {
      scale: 1, // Scale back to 1
      rotate: 0,
      fill:'red',
      transition: {
        duration: 0.6, // Adjust duration as needed
        ease: 'easeInOut',
      },
    },
  };



  return (
    <svg
      // width="273.31158mm"
      // height="90.321625mm"
      viewBox="0 0 312 110"
      id="svg535"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:svg="http://www.w3.org/2000/svg"
      style={{
        maxWidth: '95%', margin: "auto",
        display: 'flex', justifyContent: 'center', alignItems: 'center',
     

      }}
    >
      <defs id="defs532" />,
      <motion.g id="layer1" transform="translate(42.74798,-140.23137)"
      style={{ border: '1px solid red',
      
       }}>
        <motion.g
          aria-label="EMPOWERING EXCELLENCE  with IT &amp;  AUTOMATION SOLUTIONS"
          id="text710"
          style={{
            fontWeight: "bold",
            fontSize: "22.5778px",
            fontFamily: "Montserrat",
            InkscapeFontSpecification: "'Montserrat Bold'",
            textAlign: "center",
            textAnchor: "middle",
            padding: '10px', 
            border: '1px solid red'
            // stroke: "#000000",
          }}
          ref={controls}
          initial="initial"
          animate={isInView ? 'animate' : 'initial'}
          variants={{
            initial: {},
            animate: {},
          }}
          onAnimationComplete={() => {
            anotherControls.start("scaleBack"); // Apply scaleBack variant after animation completes
          }}
        >
          {React.Children.map(
            [
              <motion.path
                variants={letterVariants}
                d="m 31.140554,147.36924 h 7.608719 v 2.8448 h -7.608719 z m 0.270934,6.50241 h 8.602142 v 2.93511 H 27.776462 V 141.0023 h 11.943656 v 2.93512 h -8.30863 z"
                id="path772"
              />,
              <motion.path
                variants={letterVariants}
                d="M 42.926155,156.80676 V 141.0023 h 3.025426 l 6.728184,11.15344 h -1.603024 l 6.615296,-11.15344 h 3.002848 l 0.04516,15.80446 h -3.431826 l -0.02258,-10.54383 h 0.632178 l -5.283205,8.87308 h -1.64818 l -5.396094,-8.87308 h 0.767645 v 10.54383 z"
                id="path774"
              />,
              <motion.path
                variants={letterVariants}
                d="M 64.487952,156.80676 V 141.0023 h 6.841074 q 2.122313,0 3.657604,0.69991 1.53529,0.67734 2.370669,1.96427 0.835379,1.28694 0.835379,3.07058 0,1.76107 -0.835379,3.04801 -0.835379,1.28693 -2.370669,1.98684 -1.535291,0.67734 -3.657604,0.67734 h -4.809071 l 1.625601,-1.64818 v 6.00569 z m 3.657604,-5.59929 -1.625601,-1.73849 h 4.605871 q 1.693335,0 2.528714,-0.72249 0.835378,-0.72249 0.835378,-2.00943 0,-1.30951 -0.835378,-2.032 -0.835379,-0.72249 -2.528714,-0.72249 h -4.605871 l 1.625601,-1.73849 z"
                id="path776"
              />,
              <motion.path
                
                d="m 88.691363,157.0777 q -1.873957,0 -3.476981,-0.6096 -1.580446,-0.6096 -2.754492,-1.71592 -1.151468,-1.10631 -1.806224,-2.59644 -0.632178,-1.49014 -0.632178,-3.25121 0,-1.76107 0.632178,-3.2512 0.654756,-1.49014 1.828802,-2.59645 1.174046,-1.10631 2.754492,-1.71591 1.580446,-0.6096 3.431825,-0.6096 1.873958,0 3.431826,0.6096 1.580446,0.6096 2.731914,1.71591 1.174046,1.10631 1.828802,2.59645 0.654756,1.46756 0.654756,3.2512 0,1.76107 -0.654756,3.27378 -0.654756,1.49014 -1.828802,2.59645 -1.151468,1.08374 -2.731914,1.69334 -1.557868,0.6096 -3.409248,0.6096 z m -0.02258,-3.11574 q 1.061157,0 1.941691,-0.36125 0.903112,-0.36124 1.580446,-1.03857 0.677334,-0.67734 1.038579,-1.60303 0.383823,-0.92569 0.383823,-2.05458 0,-1.12889 -0.383823,-2.05458 -0.361245,-0.92569 -1.038579,-1.60302 -0.654756,-0.67734 -1.557868,-1.03858 -0.903112,-0.36124 -1.964269,-0.36124 -1.061156,0 -1.964268,0.36124 -0.880535,0.36124 -1.557869,1.03858 -0.677334,0.67733 -1.061156,1.60302 -0.361245,0.92569 -0.361245,2.05458 0,1.10631 0.361245,2.05458 0.383822,0.92569 1.038579,1.60303 0.677334,0.67733 1.580446,1.03857 0.903112,0.36125 1.964268,0.36125 z"
                id="path778"
                variants = {combinedVariants}
                animate={['animate', 'scaleHorizontal']}

                style={{
                  strokeWidth:0.1,
                  
                }}
               
              
              />,
              <motion.path
                variants={letterVariants}
                d="M 103.34434,156.80676 98.174019,141.0023 h 3.793071 l 4.49298,13.99824 h -1.89653 l 4.69618,-13.99824 h 3.38667 l 4.51556,13.99824 h -1.8288 l 4.60587,-13.99824 h 3.49956 l -5.17032,15.80446 h -3.92854 l -3.99627,-12.28232 h 1.03858 l -4.10916,12.28232 z"
                id="path780"
              />,
              <motion.path
                variants={letterVariants}
                d="m 128.83469,147.36924 h 7.60872 v 2.8448 h -7.60872 z m 0.27094,6.50241 h 8.60214 v 2.93511 H 125.4706 V 141.0023 h 11.94366 v 2.93512 h -8.30863 z"
                id="path782"
              />,
              <motion.path
                variants={letterVariants}
                d="M 140.62029,156.80676 V 141.0023 h 6.84107 q 2.12232,0 3.65761,0.69991 1.53529,0.67734 2.37066,1.96427 0.83538,1.28694 0.83538,3.07058 0,1.76107 -0.83538,3.04801 -0.83537,1.26435 -2.37066,1.94169 -1.53529,0.67733 -3.65761,0.67733 h -4.80907 l 1.6256,-1.60302 v 6.00569 z m 10.04712,0 -3.95112,-5.73476 h 3.90596 l 3.99627,5.73476 z m -6.38952,-5.59929 -1.6256,-1.71591 h 4.60587 q 1.69334,0 2.52872,-0.72249 0.83537,-0.74507 0.83537,-2.03201 0,-1.30951 -0.83537,-2.032 -0.83538,-0.72249 -2.52872,-0.72249 h -4.60587 l 1.6256,-1.73849 z"
                id="path784"
              />,
              <motion.path
                variants={letterVariants}
                d="M 157.21498,156.80676 V 141.0023 h 3.65761 v 15.80446 z"
                id="path786"
              />,
              <motion.path
                variants={letterVariants}
                d="M 164.62049,156.80676 V 141.0023 h 3.02542 l 9.32463,11.37921 h -1.46755 V 141.0023 h 3.61244 v 15.80446 h -3.00284 l -9.34721,-11.37921 h 1.46755 v 11.37921 z"
                id="path788"
              />,
              <motion.path
                variants={letterVariants}
                d="m 190.49466,157.0777 q -1.87396,0 -3.45441,-0.58703 -1.55786,-0.6096 -2.73191,-1.71591 -1.15147,-1.10631 -1.80622,-2.59645 -0.63218,-1.49013 -0.63218,-3.27378 0,-1.78364 0.63218,-3.27378 0.65475,-1.49013 1.8288,-2.59645 1.17404,-1.10631 2.75449,-1.69333 1.58045,-0.6096 3.47698,-0.6096 2.09974,0 3.77049,0.69991 1.69334,0.69991 2.84481,2.032 l -2.34809,2.16747 q -0.85796,-0.90311 -1.87396,-1.33209 -1.016,-0.45155 -2.21263,-0.45155 -1.15146,0 -2.09973,0.36124 -0.94827,0.36124 -1.64818,1.03858 -0.67734,0.67733 -1.06116,1.60302 -0.36124,0.92569 -0.36124,2.05458 0,1.10631 0.36124,2.032 0.38382,0.92569 1.06116,1.62561 0.69991,0.67733 1.6256,1.03857 0.94827,0.36125 2.07716,0.36125 1.08373,0 2.09973,-0.33867 1.03858,-0.36124 2.00943,-1.19662 l 2.07715,2.6416 q -1.28693,0.97085 -3.00284,1.49014 -1.69334,0.51929 -3.38667,0.51929 z m 3.048,-2.48356 v -5.93796 h 3.34151 v 6.41209 z"
                id="path790"
              />,
              <motion.path
                variants={letterVariants}
                d="m 36.886592,175.59149 h 7.608719 v 2.84481 h -7.608719 z m 0.270933,6.50241 h 8.602142 v 2.93511 H 33.522499 v -15.80446 h 11.943657 v 2.93512 h -8.308631 z"
                id="path792"
              />,
              <motion.path
                variants={letterVariants}
                d="m 46.707925,185.02901 6.795918,-9.36978 v 2.66418 l -6.502407,-9.09886 h 4.154316 l 4.492982,6.34437 -1.738491,0.0226 4.425249,-6.36694 h 3.973693 l -6.457251,8.94081 v -2.61902 l 6.841074,9.48267 h -4.222049 l -4.651027,-6.7056 h 1.670757 l -4.583293,6.7056 z"
                id="path794"
              />,
              <motion.path
                variants={letterVariants}
                d="m 71.633842,185.29995 q -1.828802,0 -3.409248,-0.58703 -1.557868,-0.6096 -2.709336,-1.71591 -1.151468,-1.10631 -1.806224,-2.59645 -0.632179,-1.49013 -0.632179,-3.27378 0,-1.78364 0.632179,-3.27378 0.654756,-1.49013 1.806224,-2.59644 1.174045,-1.10632 2.731914,-1.69334 1.557868,-0.6096 3.409248,-0.6096 2.054579,0 3.702759,0.72249 1.670757,0.69991 2.799647,2.07716 l -2.348091,2.16747 q -0.812801,-0.92569 -1.806224,-1.37725 -0.993423,-0.47413 -2.167469,-0.47413 -1.106312,0 -2.032002,0.36124 -0.92569,0.36125 -1.603024,1.03858 -0.677334,0.67733 -1.061157,1.60302 -0.361244,0.92569 -0.361244,2.05458 0,1.12889 0.361244,2.05458 0.383823,0.92569 1.061157,1.60303 0.677334,0.67733 1.603024,1.03858 0.92569,0.36124 2.032002,0.36124 1.174046,0 2.167469,-0.45156 0.993423,-0.47413 1.806224,-1.4224 l 2.348091,2.16747 q -1.12889,1.37725 -2.799647,2.09974 -1.64818,0.72249 -3.725337,0.72249 z"
                id="path796"
              />,
              <motion.path
                variants={letterVariants}
                d="m 83.983897,175.59149 h 7.608719 v 2.84481 h -7.608719 z m 0.270934,6.50241 h 8.602142 v 2.93511 H 80.619805 v -15.80446 h 11.943656 v 2.93512 h -8.30863 z"
                id="path798"
              />,
              <motion.path
                variants={letterVariants}
                d="m 95.769497,185.02901 v -15.80446 h 3.657604 v 12.82419 h 7.924809 v 2.98027 z"
                id="path800"
              />,
              <motion.path
                variants={letterVariants}
                d="m 109.40649,185.02901 v -15.80446 h 3.6576 v 12.82419 h 7.92481 v 2.98027 z"
                id="path802"
              />,
              <motion.path
                variants={letterVariants}
                d="m 126.40757,175.59149 h 7.60872 v 2.84481 h -7.60872 z m 0.27094,6.50241 h 8.60214 v 2.93511 h -12.23717 v -15.80446 h 11.94366 v 2.93512 h -8.30863 z"
                id="path804"
              />,
              <motion.path
                variants={letterVariants}
                d="m 138.19318,185.02901 v -15.80446 h 3.02542 l 9.32463,11.37921 h -1.46755 v -11.37921 h 3.61244 v 15.80446 h -3.00284 l -9.34721,-11.37921 h 1.46755 v 11.37921 z"
                id="path806"
              />,
              <motion.path
                variants={letterVariants}
                d="m 163.99961,185.29995 q -1.8288,0 -3.40924,-0.58703 -1.55787,-0.6096 -2.70934,-1.71591 -1.15147,-1.10631 -1.80622,-2.59645 -0.63218,-1.49013 -0.63218,-3.27378 0,-1.78364 0.63218,-3.27378 0.65475,-1.49013 1.80622,-2.59644 1.17405,-1.10632 2.73191,-1.69334 1.55787,-0.6096 3.40925,-0.6096 2.05458,0 3.70276,0.72249 1.67076,0.69991 2.79965,2.07716 l -2.34809,2.16747 q -0.8128,-0.92569 -1.80623,-1.37725 -0.99342,-0.47413 -2.16747,-0.47413 -1.10631,0 -2.032,0.36124 -0.92569,0.36125 -1.60302,1.03858 -0.67734,0.67733 -1.06116,1.60302 -0.36124,0.92569 -0.36124,2.05458 0,1.12889 0.36124,2.05458 0.38382,0.92569 1.06116,1.60303 0.67733,0.67733 1.60302,1.03858 0.92569,0.36124 2.032,0.36124 1.17405,0 2.16747,-0.45156 0.99343,-0.47413 1.80623,-1.4224 l 2.34809,2.16747 q -1.12889,1.37725 -2.79965,2.09974 -1.64818,0.72249 -3.72534,0.72249 z"
                id="path808"
              />,
              <motion.path
                variants={letterVariants}
                d="m 176.34967,175.59149 h 7.60871 v 2.84481 h -7.60871 z m 0.27093,6.50241 h 8.60214 v 2.93511 h -12.23717 v -15.80446 h 11.94366 v 2.93512 h -8.30863 z"
                id="path810"
              />,
              <motion.path
                variants={letterVariants}
                d="m 61.383478,213.25126 -4.380093,-12.14685 h 3.318936 l 3.635026,10.45352 h -1.580446 l 3.793071,-10.45352 h 2.98027 l 3.680181,10.45352 h -1.580446 l 3.747915,-10.45352 h 3.115736 l -4.402671,12.14685 h -3.409248 l -3.228625,-8.96338 h 1.038579 l -3.341515,8.96338 z"
                id="path812"
              />,
              <motion.path
                variants={letterVariants}
                d="m 79.761814,213.25126 v -12.14685 h 3.522137 v 12.14685 z m 1.761068,-13.84019 q -0.970845,0 -1.580446,-0.56444 -0.609601,-0.56445 -0.609601,-1.39983 0,-0.83537 0.609601,-1.39982 0.609601,-0.56444 1.580446,-0.56444 0.970846,0 1.580446,0.54186 0.609601,0.51929 0.609601,1.35467 0,0.88053 -0.609601,1.46756 -0.587023,0.56444 -1.580446,0.56444 z"
                id="path814"
              />,
              <motion.path
                variants={letterVariants}
                d="m 91.524861,213.43189 q -2.144891,0 -3.341515,-1.08374 -1.196623,-1.10631 -1.196623,-3.27378 v -10.65672 h 3.522137 v 10.61157 q 0,0.76764 0.4064,1.19662 0.4064,0.4064 1.106312,0.4064 0.835379,0 1.422402,-0.45156 l 0.948267,2.48356 q -0.541867,0.38382 -1.309512,0.58702 -0.745067,0.18063 -1.557868,0.18063 z m -6.412096,-9.34721 v -2.70934 h 8.42152 v 2.70934 z"
                id="path816"
              />,
              <motion.path
                variants={letterVariants}
                d="m 103.76202,200.92379 q 1.44498,0 2.57387,0.58702 1.15146,0.56444 1.80622,1.76107 0.65476,1.17404 0.65476,3.02542 v 6.95396 h -3.52214 v -6.41209 q 0,-1.46756 -0.65476,-2.16747 -0.63218,-0.69991 -1.80622,-0.69991 -0.83538,0 -1.51271,0.36124 -0.65476,0.33867 -1.03858,1.06116 -0.361248,0.72249 -0.361248,1.85138 v 6.00569 h -3.522136 v -16.75272 h 3.522136 v 7.96996 l -0.790223,-1.016 q 0.654757,-1.2192 1.873961,-1.87396 1.2192,-0.65475 2.77707,-0.65475 z"
                id="path818"
              />,
              <motion.path
               
                d="M 118.61823,213.25126 V 197.4468 h 3.6576 v 15.80446 z"
                id="path820"
                variants={bonusVariants}
              />,
              <motion.path
                variants={letterVariants}
                d="m 129.29751,213.25126 v -12.82419 h -5.05742 v -2.98027 h 13.77245 v 2.98027 h -5.05742 v 12.82419 z"
                id="path822"
               
              />,
              <motion.path
               
                d="m 151.33346,213.5222 q -1.73849,0 -3.09316,-0.56445 -1.35466,-0.56444 -2.14489,-1.53529 -0.79022,-0.97084 -0.79022,-2.2352 0,-1.17405 0.51929,-2.09974 0.51929,-0.94826 1.64818,-1.76106 1.12889,-0.83538 2.86738,-1.62561 1.26436,-0.58702 1.98685,-1.03857 0.72248,-0.45156 1.016,-0.85796 0.29351,-0.42898 0.29351,-0.85796 0,-0.58702 -0.4064,-0.94826 -0.38383,-0.36125 -1.15147,-0.36125 -0.8128,0 -1.26436,0.4064 -0.45155,0.4064 -0.45155,1.03858 0,0.33867 0.13546,0.67733 0.13547,0.31609 0.51929,0.74507 0.38383,0.4064 1.08374,1.06116 l 8.33121,7.72161 -1.91912,2.2352 -9.18916,-8.53441 q -0.79023,-0.72249 -1.26436,-1.35467 -0.47413,-0.63218 -0.69991,-1.24178 -0.2032,-0.6096 -0.2032,-1.26435 0,-1.15147 0.6096,-2.03201 0.6096,-0.88053 1.71591,-1.35466 1.10631,-0.49672 2.59645,-0.49672 1.37725,0 2.37067,0.45156 1.016,0.42898 1.58044,1.24178 0.58703,0.8128 0.58703,1.94169 0,0.99342 -0.49671,1.8288 -0.49672,0.8128 -1.55787,1.53529 -1.03858,0.72249 -2.73192,1.46756 -1.15146,0.51929 -1.8288,1.016 -0.65475,0.47413 -0.94827,0.97085 -0.29351,0.49671 -0.29351,1.10631 0,0.56444 0.33867,1.016 0.36124,0.45155 0.99342,0.69991 0.63218,0.24836 1.49014,0.24836 1.37724,0 2.52871,-0.63218 1.15147,-0.65476 1.94169,-1.91911 0.8128,-1.28694 1.15147,-3.11574 l 2.82223,0.88053 q -0.47414,2.30294 -1.69334,3.99627 -1.2192,1.69334 -3.00285,2.61903 -1.78364,0.92569 -3.99627,0.92569 z"
                id="path824"
                variants={bonusVariants}
              />,
              <motion.path
                variants={letterVariants}
                d="m -41.977706,241.47352 7.044274,-15.80447 h 3.612448 l 7.066851,15.80447 h -3.838226 l -5.779917,-13.95309 h 1.44498 l -5.802495,13.95309 z m 3.522137,-3.38667 0.970845,-2.77707 h 8.128008 l 0.993424,2.77707 z"
                id="path826"
              />,
              <motion.path
               
                d="m -15.922893,241.74445 q -3.364092,0 -5.260628,-1.87396 -1.896535,-1.87396 -1.896535,-5.35094 v -8.8505 h 3.657604 v 8.71504 q 0,2.25778 0.92569,3.2512 0.925689,0.99342 2.596447,0.99342 1.670757,0 2.596447,-0.99342 0.92569,-0.99342 0.92569,-3.2512 v -8.71504 h 3.6124477 v 8.8505 q 0,3.47698 -1.8965357,5.35094 -1.896535,1.87396 -5.260627,1.87396 z"
                id="path828"
                variants={bonusVariants}
              />,
              <motion.path
                variants={letterVariants}
                d="m -2.1504108,241.47352 v -12.8242 h -5.0574274 v -2.98027 H 6.5646203 v 2.98027 H 1.5071929 v 12.8242 z"
                id="path830"
              />,
              <motion.path
                
                d="m 15.979555,241.74445 q -1.873957,0 -3.476981,-0.6096 -1.580446,-0.6096 -2.7544918,-1.71591 -1.1514678,-1.10632 -1.806224,-2.59645 -0.6321785,-1.49014 -0.6321785,-3.2512 0,-1.76107 0.6321785,-3.25121 0.6547562,-1.49013 1.8288018,-2.59645 1.174046,-1.10631 2.754492,-1.71591 1.580446,-0.6096 3.431825,-0.6096 1.873958,0 3.431826,0.6096 1.580446,0.6096 2.731914,1.71591 1.174046,1.10632 1.828802,2.59645 0.654756,1.46756 0.654756,3.25121 0,1.76106 -0.654756,3.27378 -0.654756,1.49013 -1.828802,2.59644 -1.151468,1.08374 -2.731914,1.69334 -1.557868,0.6096 -3.409248,0.6096 z m -0.02258,-3.11574 q 1.061157,0 1.941691,-0.36124 0.903112,-0.36125 1.580446,-1.03858 0.677334,-0.67734 1.038579,-1.60302 0.383823,-0.92569 0.383823,-2.05458 0,-1.12889 -0.383823,-2.05458 -0.361245,-0.92569 -1.038579,-1.60303 -0.654756,-0.67733 -1.557868,-1.03858 -0.903112,-0.36124 -1.964269,-0.36124 -1.061156,0 -1.964268,0.36124 -0.880534,0.36125 -1.557868,1.03858 -0.677335,0.67734 -1.061157,1.60303 -0.361245,0.92569 -0.361245,2.05458 0,1.10631 0.361245,2.05458 0.383822,0.92568 1.038579,1.60302 0.677334,0.67733 1.580446,1.03858 0.903112,0.36124 1.964268,0.36124 z"
                id="path832"
                variants={combinedVariants}
                animate={['animate', 'scaleHorizontal']}
                style={{
                  strokeWidth:0.1,
                }}
              />,
              <motion.path
                variants={letterVariants}
                d="m 27.358767,241.47352 v -15.80447 h 3.025425 l 6.728185,11.15344 h -1.603024 l 6.615296,-11.15344 h 3.002847 l 0.04516,15.80447 H 41.74083 l -0.02258,-10.54384 h 0.632179 l -5.283206,8.87308 h -1.648179 l -5.396094,-8.87308 h 0.767645 v 10.54384 z"
                id="path834"
              />,
              <motion.path
                
                d="m 46.843407,241.47352 7.044274,-15.80447 h 3.612448 l 7.066852,15.80447 h -3.838226 l -5.779917,-13.95309 h 1.444979 l -5.802495,13.95309 z m 3.522137,-3.38667 0.970846,-2.77707 h 8.128008 l 0.993423,2.77707 z"
                id="path836"
                variants={bonusVariants}
              />,
              <motion.path
                variants={letterVariants}
                d="m 68.698733,241.47352 v -12.8242 h -5.057427 v -2.98027 h 13.772458 v 2.98027 h -5.057427 v 12.8242 z"
                id="path838"
              />,
              <motion.path
                
                d="m 79.378039,241.47352 v -15.80447 h 3.657604 v 15.80447 z"
                id="path840"
                variants={bonusVariants}
              />,
              <motion.path
                
                d="m 94.459986,241.74445 q -1.873958,0 -3.476981,-0.6096 -1.580447,-0.6096 -2.754492,-1.71591 -1.151468,-1.10632 -1.806224,-2.59645 -0.632179,-1.49014 -0.632179,-3.2512 0,-1.76107 0.632179,-3.25121 0.654756,-1.49013 1.828802,-2.59645 1.174045,-1.10631 2.754491,-1.71591 1.580446,-0.6096 3.431826,-0.6096 1.873957,0 3.431826,0.6096 1.580446,0.6096 2.731916,1.71591 1.17404,1.10632 1.8288,2.59645 0.65476,1.46756 0.65476,3.25121 0,1.76106 -0.65476,3.27378 -0.65476,1.49013 -1.8288,2.59644 -1.15147,1.08374 -2.731916,1.69334 -1.557869,0.6096 -3.409248,0.6096 z m -0.02258,-3.11574 q 1.061157,0 1.941691,-0.36124 0.903112,-0.36125 1.580446,-1.03858 0.677334,-0.67734 1.038579,-1.60302 0.383822,-0.92569 0.383822,-2.05458 0,-1.12889 -0.383822,-2.05458 -0.361245,-0.92569 -1.038579,-1.60303 -0.654756,-0.67733 -1.557868,-1.03858 -0.903112,-0.36124 -1.964269,-0.36124 -1.061157,0 -1.964269,0.36124 -0.880534,0.36125 -1.557868,1.03858 -0.677334,0.67734 -1.061157,1.60303 -0.361244,0.92569 -0.361244,2.05458 0,1.10631 0.361244,2.05458 0.383823,0.92568 1.038579,1.60302 0.677334,0.67733 1.580446,1.03858 0.903112,0.36124 1.964269,0.36124 z"
                id="path842"
                variants = {combinedVariants}
                animate={['animate', 'scaleHorizontal']}
                style={{
                  strokeWidth:0.1,
                }}
              />,
              <motion.path
               
                d="m 105.8392,241.47352 v -15.80447 h 3.02542 l 9.32463,11.37922 h -1.46755 v -11.37922 h 3.61245 v 15.80447 h -3.00285 l -9.34721,-11.37922 h 1.46756 v 11.37922 z"
                id="path844"
                variants={bonusVariants}
              />,
              <motion.path
                variants={letterVariants}
                d="m 135.66451,241.74445 q -1.89654,0 -3.63503,-0.49671 -1.73849,-0.51929 -2.79965,-1.33209 l 1.24178,-2.75449 q 1.016,0.72249 2.39325,1.19662 1.39982,0.45156 2.82223,0.45156 1.08373,0 1.73849,-0.20321 0.67733,-0.22577 0.99342,-0.6096 0.31609,-0.38382 0.31609,-0.88053 0,-0.63218 -0.49671,-0.99342 -0.49671,-0.38383 -1.30952,-0.6096 -0.8128,-0.24836 -1.80622,-0.45156 -0.97085,-0.22578 -1.96427,-0.54187 -0.97084,-0.31609 -1.78364,-0.8128 -0.81281,-0.49671 -1.33209,-1.30951 -0.49672,-0.8128 -0.49672,-2.07716 0,-1.35467 0.72249,-2.46098 0.74507,-1.12889 2.21263,-1.78364 1.49013,-0.67734 3.72533,-0.67734 1.49014,0 2.93512,0.36125 1.44498,0.33866 2.55129,1.03858 l -1.12889,2.77706 q -1.10631,-0.63217 -2.21262,-0.92569 -1.10632,-0.31608 -2.16747,-0.31608 -1.06116,0 -1.73849,0.24835 -0.67734,0.24836 -0.97085,0.65476 -0.29351,0.38382 -0.29351,0.90311 0,0.6096 0.49671,0.99342 0.49671,0.36125 1.30951,0.58703 0.8128,0.22577 1.78365,0.45155 0.99342,0.22578 1.96427,0.51929 0.99342,0.29351 1.80622,0.79022 0.8128,0.49672 1.30951,1.30952 0.51929,0.8128 0.51929,2.05458 0,1.33209 -0.74506,2.4384 -0.74507,1.10631 -2.23521,1.78365 -1.46755,0.67733 -3.72533,0.67733 z"
                id="path846"
              />,
              <motion.path
               
                d="m 152.55271,241.74445 q -1.87395,0 -3.47698,-0.6096 -1.58044,-0.6096 -2.75449,-1.71591 -1.15147,-1.10632 -1.80622,-2.59645 -0.63218,-1.49014 -0.63218,-3.2512 0,-1.76107 0.63218,-3.25121 0.65475,-1.49013 1.8288,-2.59645 1.17404,-1.10631 2.75449,-1.71591 1.58045,-0.6096 3.43183,-0.6096 1.87395,0 3.43182,0.6096 1.58045,0.6096 2.73192,1.71591 1.17404,1.10632 1.8288,2.59645 0.65475,1.46756 0.65475,3.25121 0,1.76106 -0.65475,3.27378 -0.65476,1.49013 -1.8288,2.59644 -1.15147,1.08374 -2.73192,1.69334 -1.55787,0.6096 -3.40925,0.6096 z m -0.0226,-3.11574 q 1.06115,0 1.94169,-0.36124 0.90311,-0.36125 1.58044,-1.03858 0.67734,-0.67734 1.03858,-1.60302 0.38382,-0.92569 0.38382,-2.05458 0,-1.12889 -0.38382,-2.05458 -0.36124,-0.92569 -1.03858,-1.60303 -0.65475,-0.67733 -1.55787,-1.03858 -0.90311,-0.36124 -1.96426,-0.36124 -1.06116,0 -1.96427,0.36124 -0.88054,0.36125 -1.55787,1.03858 -0.67734,0.67734 -1.06116,1.60303 -0.36124,0.92569 -0.36124,2.05458 0,1.10631 0.36124,2.05458 0.38382,0.92568 1.03858,1.60302 0.67733,0.67733 1.58045,1.03858 0.90311,0.36124 1.96427,0.36124 z"
                id="path848"
                variants = {combinedVariants}
                animate={['animate', 'scaleHorizontal']}
                style={{
                  strokeWidth:0.1,
                }}
              />,
              <motion.path
                variants={letterVariants}
                d="m 163.93193,241.47352 v -15.80447 h 3.6576 v 12.8242 h 7.92481 v 2.98027 z"
                id="path850"
              />,
              <motion.path
                variants={letterVariants}
                d="m 184.38738,241.74445 q -3.36409,0 -5.26062,-1.87396 -1.89654,-1.87396 -1.89654,-5.35094 v -8.8505 h 3.6576 v 8.71504 q 0,2.25778 0.92569,3.2512 0.92569,0.99342 2.59645,0.99342 1.67076,0 2.59645,-0.99342 0.92569,-0.99342 0.92569,-3.2512 v -8.71504 h 3.61245 v 8.8505 q 0,3.47698 -1.89654,5.35094 -1.89653,1.87396 -5.26063,1.87396 z"
                id="path852"
              />,
              <motion.path
                variants={letterVariants}
                d="m 198.15987,241.47352 v -12.8242 h -5.05743 v -2.98027 h 13.77246 v 2.98027 h -5.05743 v 12.8242 z"
                id="path854"
              />,
              <motion.path
                variants={letterVariants}
                d="m 208.83917,241.47352 v -15.80447 h 3.6576 v 15.80447 z"
                id="path856"
              />,
              <motion.path
               
                d="m 223.92111,241.74445 q -1.87396,0 -3.47698,-0.6096 -1.58045,-0.6096 -2.7545,-1.71591 -1.15146,-1.10632 -1.80622,-2.59645 -0.63218,-1.49014 -0.63218,-3.2512 0,-1.76107 0.63218,-3.25121 0.65476,-1.49013 1.8288,-2.59645 1.17405,-1.10631 2.75449,-1.71591 1.58045,-0.6096 3.43183,-0.6096 1.87396,0 3.43182,0.6096 1.58045,0.6096 2.73192,1.71591 1.17404,1.10632 1.8288,2.59645 0.65476,1.46756 0.65476,3.25121 0,1.76106 -0.65476,3.27378 -0.65476,1.49013 -1.8288,2.59644 -1.15147,1.08374 -2.73192,1.69334 -1.55786,0.6096 -3.40924,0.6096 z m -0.0226,-3.11574 q 1.06116,0 1.94169,-0.36124 0.90311,-0.36125 1.58045,-1.03858 0.67733,-0.67734 1.03857,-1.60302 0.38383,-0.92569 0.38383,-2.05458 0,-1.12889 -0.38383,-2.05458 -0.36124,-0.92569 -1.03857,-1.60303 -0.65476,-0.67733 -1.55787,-1.03858 -0.90311,-0.36124 -1.96427,-0.36124 -1.06116,0 -1.96427,0.36124 -0.88053,0.36125 -1.55787,1.03858 -0.67733,0.67734 -1.06115,1.60303 -0.36125,0.92569 -0.36125,2.05458 0,1.10631 0.36125,2.05458 0.38382,0.92568 1.03857,1.60302 0.67734,0.67733 1.58045,1.03858 0.90311,0.36124 1.96427,0.36124 z"
                id="path858"
                variants = {combinedVariants}
                animate={['animate', 'scaleHorizontal']}
                style={{
                  strokeWidth:0.1,
                }}
              />,
              <motion.path
                variants={letterVariants}
                d="m 235.30032,241.47352 v -15.80447 h 3.02542 l 9.32464,11.37922 h -1.46756 v -11.37922 h 3.61245 v 15.80447 h -3.00285 l -9.34721,-11.37922 h 1.46756 v 11.37922 z"
                id="path860"
              />,
              <motion.path
                variants={letterVariants}
                d="m 258.7361,241.74445 q -1.89653,0 -3.63502,-0.49671 -1.73849,-0.51929 -2.79965,-1.33209 l 1.24178,-2.75449 q 1.016,0.72249 2.39324,1.19662 1.39983,0.45156 2.82223,0.45156 1.08373,0 1.73849,-0.20321 0.67733,-0.22577 0.99342,-0.6096 0.31609,-0.38382 0.31609,-0.88053 0,-0.63218 -0.49671,-0.99342 -0.49671,-0.38383 -1.30951,-0.6096 -0.8128,-0.24836 -1.80623,-0.45156 -0.97084,-0.22578 -1.96426,-0.54187 -0.97085,-0.31609 -1.78365,-0.8128 -0.8128,-0.49671 -1.33209,-1.30951 -0.49671,-0.8128 -0.49671,-2.07716 0,-1.35467 0.72249,-2.46098 0.74506,-1.12889 2.21262,-1.78364 1.49014,-0.67734 3.72534,-0.67734 1.49013,0 2.93511,0.36125 1.44498,0.33866 2.55129,1.03858 l -1.12889,2.77706 q -1.10631,-0.63217 -2.21262,-0.92569 -1.10631,-0.31608 -2.16747,-0.31608 -1.06116,0 -1.73849,0.24835 -0.67733,0.24836 -0.97084,0.65476 -0.29352,0.38382 -0.29352,0.90311 0,0.6096 0.49672,0.99342 0.49671,0.36125 1.30951,0.58703 0.8128,0.22577 1.78364,0.45155 0.99343,0.22578 1.96427,0.51929 0.99343,0.29351 1.80623,0.79022 0.8128,0.49672 1.30951,1.30952 0.51929,0.8128 0.51929,2.05458 0,1.33209 -0.74507,2.4384 -0.74507,1.10631 -2.2352,1.78365 -1.46756,0.67733 -3.72534,0.67733 z"
                id="path862"
              />,

            ],

            (path, index) =>
              React.cloneElement(path, {
                // variants: letterVariants,
                custom: index,
              })

          )}

        </motion.g>
      </motion.g>
    </svg>
  )
}
export default SvgTextMotto
