import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';


import 'leaflet/dist/leaflet.css';

import './map.css'; // Import CSS file for styling
import { useAppContext } from './AppContext';
import CustomWave from './CustomWave.js';
import CustomWaveBottom from './CustomWaveBottom.js';
import SvgLoading from './svgComponents/SvgLoading.tsx';


const Map = () => {
    const { currentLanguage } = useAppContext();

    const [position, setPosition] = useState([0, 0]);
    const [isMapLoading, setIsMapLoading] = useState(true);
    // const [position, setPosition] = useState( [37.4217636, -122.084614]);


    // const companyAddress = '1600 Amphitheatre Parkway, Mountain View, CA 94043, USA';
    const companyAddress = 'Adidas, Africa Avenue / Bole Road, Olympia, Kirkos, Addis Ababa, 2332, Ethiopia';

    const companyAddress2 = 'Getu commercial, Africa Avenue / Bole Road, Olympia, Kirkos, Addis Ababa, 2332, Ethiopia'
    const zoom = 15;

    useEffect(() => {
        const fetchCoordinates = async () => {
            try {
                console.log(`Fetching coordinates for address: ${companyAddress}`);
                const response = await fetch(
                    `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
                        companyAddress
                    )}&format=json`
                );
                const data = await response.json();
                console.log('Nominatim API response:', data);

                if (data.length > 0) {
                    const { lat, lon } = data[0];
                    setPosition([lat, lon]);
                    setIsMapLoading(false);
                    console.log(`Coordinates fetched: [${lat}, ${lon}]`);
                    console.log('Position: ', position);
                } else {
                    console.error('Nominatim API did not return any results');
                    setIsMapLoading(false);
                }
            } catch (error) {
                console.error('Error fetching coordinates:', error);
                setIsMapLoading(false);
            }
        };

        fetchCoordinates();
    }, [companyAddress]);


    // Use one of the built-in Leaflet icons
    const markerIcon = L.icon({
        iconUrl: 'https://unpkg.com/leaflet@1.9.3/dist/images/marker-icon.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
    });





    return (
        <div className='sub-container'

        style={{backgroundColor:'#5353f663',}}
        >
            <CustomWave fillColor={'#FFD9E8'} waveHeight={'9rem'} />
            {currentLanguage === 'English' && (
<>
                <div className='map-container'
             >
                    
                    <h2>Company Location</h2>
                    <p>Bole Road Bahir Bldg 1st Floor office 1-7 <span>In front of Getu Commercial Addis Ababa, Ethiopia</span></p>
                    {isMapLoading ? (                        

                            <div className="loader-map">

                                <SvgLoading />
                            </div>
                        
                    ) : position ? (
                        <MapContainer center={position} zoom={zoom} style={{ height: '25rem', width: '80%' }}
                            className="map">
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <Marker position={position} icon={markerIcon}>
                                <Popup>{companyAddress}</Popup>
                            </Marker>
                        </MapContainer>
                    ) : (
                        <div>Error loading map</div>
                    )}
                </div>

                <CustomWaveBottom fillColor={'#FFFFFF'} waveHeight={'7.5rem'} />
                </>

                

            )}
            {currentLanguage === 'አማርኛ' && (
                <div className='sub-container'>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h1>መነሻ ገጽ</h1>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Map;