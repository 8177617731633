import React, { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState('English');
  const [currentPage, setCurrentPage] = useState('Home');
  const initialCartItems = [
    // { id: 1, name: 'Product 1', price: 10000, quantity: 2 },
   
    // Add more initial products as needed
];

const [cartItems, setCartItems] = useState(initialCartItems);

  const [orderComplete, setOrderComplete] = useState(false);

  const handleLanguageChange = () => {
    setCurrentLanguage((prevLanguage) => (prevLanguage === 'English' ? 'አማርኛ' : 'English'));
  };

  const setPage = (page) => {
    setCurrentPage(page);
  };

  const addToCart = (product) => {
    setCartItems([...cartItems, { ...product, quantity: 1 }]);
  };

  const removeFromCart = (index) => {
    const newCartItems = cartItems.filter((_, i) => i !== index);
    setCartItems(newCartItems);
  };

  const updateQuantity = (index, newQuantity) => {
    const newCartItems = [...cartItems];
    newCartItems[index].quantity = newQuantity;
    setCartItems(newCartItems);
  };

  return (
    <AppContext.Provider
      value={{
        currentLanguage,
        handleLanguageChange,
        currentPage,
        setPage,
        cartItems,
        setCartItems,
        addToCart,
        removeFromCart,
        updateQuantity,
        key: `${currentLanguage}-${currentPage}`,
        orderComplete,
        setOrderComplete,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};