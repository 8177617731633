import React, { useEffect, useState } from 'react';
import './admin-testimony.css'; // Import CSS file for styling
import { useAppContext } from './AppContext';

import { FaEye, FaEyeSlash, FaCopy, FaTrash } from 'react-icons/fa';
import axios from 'axios';

// const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
const API_URL = 'https://api-netconn.brosfe.com';


const AdminTestimony = () => {
    const { currentLanguage } = useAppContext();
    
    const [isLoading, setIsLoading] = useState(true);
    const [testimonies, setTestimonies] = useState([]);
  const [newTestimony, setNewTestimony] = useState({ image: '', name: '', description: '' });
    const [isClipboardCopied, setIsClipboardCopied] = useState(false);
    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
          fetchTestimony();
        }
      }, []);

 
    
        
      useEffect(() => {
        if (isClipboardCopied) {
          setTimeout(() => {
            setIsClipboardCopied(false);
          }, 1000);
        }
      }, [isClipboardCopied]);

    const fetchTestimony = async () => {
        const response = await axios.get(`${API_URL}/api/testimonies`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
        });
        setTestimonies(response.data);
        setIsLoading(false);
      };
  const handleTestimonyChange = (e) => {
    setNewTestimony({ ...newTestimony, [e.target.name]: e.target.value });
  };

  const handleTestimoniesSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_URL}/api/testimonies`, newTestimony, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
      });
      setNewTestimony({ quote: '', name: '', year: '',  });
      fetchTestimony();
    } catch (error) {
      console.error('Error creating testimony:', error);
    }
  };

  const handleTestimonyDelete = async (id) => {
    await axios.delete(`${API_URL}/api/testimonies/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
    });
    fetchTestimony();
  };

  const truncateString = (str, maxWords) => {
    // Split the string into words
    const words = str.split(' ');

    // Check if the number of words exceeds the maxWords limit
    if (words.length > maxWords) {
        // Truncate the string to the maxWords limit and join the words back
        const truncated = words.slice(0, maxWords).join(' ');
        
        // Add ellipsis at the end
        return truncated + '...';
    }

    // If the string doesn't exceed the maxWords limit, return it as is
    return str;
}

    return (
        < >
            {currentLanguage === 'English' && (

                <div className='sub-container'>

                                   
                    <div className='admin-content-form'>
                       
                        <form onSubmit={handleTestimoniesSubmit}>
                        <h3>Manage your Testimonies here</h3>

                        <textarea
                                name="quote"
                                placeholder="Quote"
                                value={newTestimony.quote}
                                onChange={handleTestimonyChange}
                            ></textarea>

                          
                            <input
                                type="text"
                                name="name"
                                placeholder="Name or company"
                                value={newTestimony.name}
                                onChange={handleTestimonyChange}
                            />

                            <input
                                type="number"
                                name="year"
                                placeholder="Year"
                                value={newTestimony.year}
                                onChange={handleTestimonyChange}
                                />
                           
                            <button type="submit">Add testimony</button>
                        </form>
                        {isLoading && <p>Loading...</p>}
                        <table>
                            <thead>
                                <tr>
                                    <th>Quote</th>
                                    <th>Name</th>
                                    <th>Year</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {testimonies.map((testimony) => (
                                    <tr key={testimony._id}>
                                        <td>{truncateString(testimony.quote, 10)}</td>
                                        <td>{testimony.name}</td>
                                        <td>{testimony.year}</td>
                                        <td>
                                            <button onClick={() => handleTestimonyDelete(testimony._id)}><FaTrash /> </button>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </div>







                </div>

            )}
            {currentLanguage === 'አማርኛ' && (
                <div className='sub-container'>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h1>መነሻ ገጽ</h1>
                    </div>
                </div>
            )}
        </>
    );
};

export default AdminTestimony;