import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import NavigationBar from './NavigationBar';
import { useAppContext } from './AppContext';
import ProductDetails from './ProductDetails';
import './product_page.css';
import axios from 'axios';
import { motion } from 'framer-motion';
import Footer from './Footer';
import SvgLoading from './svgComponents/SvgLoading.tsx';

// const API_URL = process.env.REACT_APP_API_URL || '';
const API_URL = 'https://api-netconn.brosfe.com';

function ProductPage() {
  const { currentLanguage, setPage } = useAppContext();
  const location = useLocation();
  const [category, setCategory] = useState(location.state?.category || '');

  useEffect(() => {
    setPage('Products/Services');
  }, []);

  const [productsDatabase, setProductsDatabase] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const prevCategory = useRef(category);

  // useEffect(() => {
  //   fetchProducts(fetchParams);
  // }, [fetchParams]);
  useEffect(() => {

   
      fetchProducts(category);
   
  
     
     
    
  }, [category]);
  



 
  const fetchProducts = async (category) => {
    console.log('fetching products', category);
    try {
      setIsLoading(true);
      setError(null);
      const response = category 
        ? await axios.get(`${API_URL}/api/products/search?query=${category}`) 
        : await axios.get(`${API_URL}/api/products`);
      setSearchResults(response.data);
     
    } catch (error) {
      setError(error.response.data.message || 'Sorry, we couldn\'t find your products.');
    } finally {
      setIsLoading(false);
      
    }
  };


  // useEffect(() => {
  //   const fetchProducts = async () => {
  //     try {
  //       setIsLoading(true);
  //       const response = await axios.get('/api/products');
  //       setProductsDatabase(response.data);
  //       setSearchResults(response.data);
  //     } catch (error) {
  //       setError('Error fetching products');
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchProducts();
  // }, []);


  const handleSearch = async () => {
    if (searchTerm.trim() === '') {
      setError('Please enter a search keyword');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);
      const response = await axios.get(`${API_URL}/api/products/search?query=${searchTerm}`);

      setSearchResults(response.data);
    } catch (error) {
      setError(error.response.data.message || 'Sorry, we couldn\'t find your products.');
    } finally {
      setIsLoading(false);
    }
  };


  const clearSearch = () => {
    setSearchTerm('');
    setError(null);
    fetchProducts();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };


  return (
    <motion.div
    initial={{ opacity: 0 }} // Initial position before movement animation
    animate={{ opacity: 1, }} // Move to the left corner of the screen
    transition={{ duration: 2, ease: 'linear' }} // Delay movement animation by 1 second
    style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}
>
     {currentLanguage === 'English' && (
        <div className='main-container'>
          <div className='sub-container'
          style={{width:'100%'}}>

            <NavigationBar />

            <div className="motto-products">
            <h2>Some of our products</h2>
            </div>



          </div>
          <div className='sub-container'>

            <div className="search-container">
            {isLoading && 

            <div className="loader">
            
            <SvgLoading />
            </div>
            }
              {error && <p className='error-message'>{error}</p>}
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Search products..."
                className="search-input"
              />
              <button onClick={handleSearch} className='search-button'>Search</button>
              <button onClick={clearSearch} className='search-button'>Clear Search</button>

             

            </div>
            <div className="products-display">
              {searchResults && (
                searchResults.map((product) => (
                  <Link to={`/products-services/${product._id}`} key={product._id}>
                    <div className="product-item" key={product._id}>
                      <img src={product.image} alt={product.name} />
                      <h4>{product.name}</h4>
                      <p>{product.description}</p>
                      <div className="price-container">
                      <p>{product.price.current}ETB</p><p>{product.price.past[0].price}ETB</p>
                      </div>
                     
                    </div>
                  </Link>
                ))
              )}


            </div>

            <div className='button-cta-products'>
              <button>SEE MORE</button>
            </div>



          </div>
          <Footer />

        </div>


      )}
     
      {currentLanguage === 'አማርኛ' && (
        <div className='sub-container'>
          <NavigationBar />

          <div className="Motto">
            <h1>ይህ የመርቶችና አገልግሎቶች ገጽ ነው ፡፡</h1>
          </div>
        </div>


      )}

    </motion.div>

  );
}

export default ProductPage;
