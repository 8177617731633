const companyPolicies = {
  codeOfConduct: {
    id: 'code-of-conduct',
    title: "Code of Conduct",
    sections: [
      {
        heading: "Introduction",
        content: "Welcome to netconn Engineering. We are committed to conducting our business ethically and in compliance with all applicable laws and regulations. This Code of Conduct outlines the standards and behaviors expected from our employees, partners, and stakeholders."
      },
      {
        heading: "Compliance with Laws and Regulations",
        content: "All employees, partners, and stakeholders must adhere to all local, national, and international laws and regulations relevant to our business operations. Ignorance of the law is not an excuse for non-compliance."
      },
      {
        heading: "Ethical Business Practices",
        content: [
          "Integrity and Honesty: Conduct all business activities with integrity and honesty. Avoid any form of deceit or fraud.",
          "Transparency: Maintain transparency in all dealings, ensuring that information is communicated accurately and promptly.",
          "Fair Competition: Engage in fair competition practices. Do not engage in anti-competitive behaviors such as price-fixing, monopolistic practices, or bid-rigging."
        ]
      },
      {
        heading: "Confidentiality and Data Protection",
        content: [
          "Confidential Information: Protect all confidential information related to our company, clients, and partners. Do not disclose any confidential information without proper authorization.",
          "Data Protection: Comply with all data protection laws and regulations. Ensure that personal data is handled with the utmost care and security."
        ]
      },
      {
        heading: "Workplace Conduct",
        content: [
          "Respect and Dignity: Treat all colleagues, clients, and partners with respect and dignity. Discrimination, harassment, or any form of abusive behavior will not be tolerated.",
          "Health and Safety: Adhere to all health and safety regulations to provide a safe working environment for all employees.",
          "Professionalism: Maintain professionalism in all interactions. Dress appropriately, communicate respectfully, and behave in a manner that reflects positively on the company."
        ]
      },
      {
        heading: "Conflicts of Interest",
        content: "Avoid any situations that may create a conflict of interest. If a potential conflict arises, disclose it to the relevant authority within the company immediately."
      },
      {
        heading: "Environmental Responsibility",
        content: "Commit to sustainable and environmentally friendly practices. Strive to minimize the environmental impact of our operations through energy efficiency, waste reduction, and responsible sourcing."
      },
      {
        heading: "Reporting Violations",
        content: "Employees and stakeholders are encouraged to report any violations of this Code of Conduct. Reports can be made anonymously and without fear of retaliation."
      },
      {
        heading: "Enforcement and Disciplinary Actions",
        content: "Non-compliance with this Code of Conduct may result in disciplinary actions, including termination of employment or business relationships. The company reserves the right to take legal action if necessary."
      }
    ]
  },
  cancellationAndRefundPolicy: {
    id: 'cancellation-refund-policy',
    title: "Cancellation and Refund Policy",
    sections: [
      {
        heading: "Introduction",
        content: "At netconn Engineering, we strive to provide the highest quality products and services. This Cancellation and Refund Policy outlines the terms and conditions under which cancellations and refunds will be processed."
      },
      {
        heading: "Cancellation Policy",
        content: [
          "Order Cancellation by Customer: Customers may cancel their orders within 24 hours of placing them. To cancel an order, contact our customer service team with your order details.",
          "Order Cancellation by Company: netconn Engineering reserves the right to cancel any order for reasons including, but not limited to, stock unavailability, payment issues, or suspected fraudulent activity. Customers will be notified of such cancellations promptly."
        ]
      },
      {
        heading: "Refund Policy",
        content: [
          "Eligibility for Refund: Refunds are available for products that are returned in their original condition, unopened, and with all original packaging and accessories. The return must be initiated within 30 days of the delivery date.",
          "Non-Refundable Items: Customized products, software licenses, and items marked as non-refundable cannot be returned or refunded.",
          "Process for Refunds:",
          "Initiate a Return: Contact our customer service team to initiate a return. Provide your order number and reason for the return.",
          "Return Authorization: Upon approval, you will receive a return authorization and instructions on how to return the item.",
          "Return Shipment: Ship the item back to us using the provided instructions. The customer is responsible for return shipping costs unless the return is due to a company error.",
          "Inspection and Refund: Once the returned item is received and inspected, a refund will be processed to the original payment method within 10 business days."
        ]
      },
      {
        heading: "Damaged or Defective Products",
        content: [
          "Reporting Damaged or Defective Products: If you receive a damaged or defective product, contact our customer service team within 7 days of receiving the item. Provide photos and a description of the issue.",
          "Replacement or Refund: Depending on the situation, we will either replace the damaged/defective product or issue a full refund, including any shipping costs incurred."
        ]
      },
      {
        heading: "Contact Information",
        content: "For any questions or concerns regarding cancellations or refunds, please contact our customer service team at [contact information]."
      },
      {
        heading: "Changes to Policy",
        content: "netconn Engineering reserves the right to modify this Cancellation and Refund Policy at any time. Any changes will be communicated to customers through our website and will take effect immediately."
      }
    ]
  }
};

export default companyPolicies;