
import React from 'react';
import { useAppContext } from './AppContext';
import amharicLanguageIcon from './assets/images/icons/amh.png';
import englishLanguageIcon from './assets/images/icons/eng.png';
import './language_selector.css';

const LanguageSelector = () => {
    const { currentLanguage, handleLanguageChange } = useAppContext();

    // Options for the dropdown menu
    const languageOptions = [
        {
            value: 'አማርኛ',
            label: 'አማርኛ',
            icon: amharicLanguageIcon
        },
        {
            value: 'English',
            label: 'English',
            icon: englishLanguageIcon
        }
    ];

    // Handle change event for dropdown menu
    const onLanguageChange = (e) => {
        handleLanguageChange(e.target.value);
    };

    return (
        <div className="language">
            <select
                value={currentLanguage}
                onChange={onLanguageChange}
                className="language-dropdown"
            >
                {languageOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        <img
                            src={option.icon}
                            alt={`${option.value} language icon`}
                            className="lang-icon"
                        />
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default LanguageSelector;

