import React from 'react';
import './app-dropdown.css'; // Import CSS file for styling
import { useAppContext } from './AppContext';
import styled from 'styled-components';


const DropdownContainer = styled.div`
position: relative;
display: inline-block;
`;

const DropdownSelect = styled.select`
background-color: white;
color: green;
padding: 0.8rem 1.2rem;
border: none;
border-radius: 4px;
cursor: pointer;
`;

const DropdownOption = styled.option``;

const AppDropdown = ({ onAppSelect }) => {
    const { currentLanguage } = useAppContext();

    const handleChange = (e) => {
        onAppSelect(e.target.value);
    };


    return (
        < >
            {currentLanguage === 'English' && (

                <>
                    <DropdownContainer>
                        <DropdownSelect onChange={handleChange}>
                            <DropdownOption value="">Select an app</DropdownOption>
                            <DropdownOption value="call">Direct Call</DropdownOption>
                            <DropdownOption value="whatsapp">WhatsApp</DropdownOption>
                            <DropdownOption value="telegram">Telegram</DropdownOption>
                        </DropdownSelect>
                    </DropdownContainer>
                </>

            )}
            {currentLanguage === 'አማርኛ' && (
                <div className='sub-container'>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h1>መነሻ ገጽ</h1>
                    </div>
                </div>
            )}
        </>
    );
};

export default AppDropdown;