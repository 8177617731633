import React, { useEffect, useState } from 'react';
import './admin-staff.css'; // Import CSS file for styling
import { useAppContext } from './AppContext';

import { FaEye, FaEyeSlash, FaCopy, FaTrash } from 'react-icons/fa';
import UploadWidgetClaudinary from './UploadWidgetClaudinary';
import axios from 'axios';

// const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
const API_URL = 'https://api-netconn.brosfe.com';
const AdminStaff = () => {
  const { currentLanguage } = useAppContext();

  const [isLoading, setIsLoading] = useState(true);
  const [staffs, setStaffs] = useState([]);
  const [newStaff, setNewStaff] = useState({ image: '', name: '', description: '' });
  const [isClipboardCopied, setIsClipboardCopied] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      fetchStaff();
    }
  }, []);

  const [originalUrl, setOriginalUrl] = useState('');
  const [convertedUrl, setConvertedUrl] = useState('');


  const convertUrl = () => {
    // Regular expression to extract the file ID from the original URL
    const fileIdMatch = originalUrl.match(/https:\/\/drive\.google\.com\/file\/d\/(.+?)\/view/);
    if (fileIdMatch) {
      const fileId = fileIdMatch[1];
      // Construct the converted URL in thumbnail format
      const thumbnailUrl = `https://drive.google.com/thumbnail?id=${fileId}`;
      setConvertedUrl(thumbnailUrl);
    } else {
      setConvertedUrl('Invalid URL');
    }
  };

  // Function to copy the converted URL to the clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(convertedUrl)
      .then(() => {
        // alert('Converted URL copied to clipboard!');
        setIsClipboardCopied(true);
      })
      .catch(() => {
        alert('Failed to copy the URL');
      });
  };

  useEffect(() => {
    if (isClipboardCopied) {
      setTimeout(() => {
        setIsClipboardCopied(false);
      }, 1000);
    }
  }, [isClipboardCopied]);

  const fetchStaff = async () => {
    const response = await axios.get(`${API_URL}/api/staffs`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
    });
    setStaffs(response.data);
    setIsLoading(false);
  };
  const handleStaffChange = (e) => {
    setNewStaff({ ...newStaff, [e.target.name]: e.target.value });
  };

  const handleStaffSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_URL}/api/staffs`, newStaff, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
      });
      setNewStaff({ image: '', name: '', role: '', description: '' });
      fetchStaff();
    } catch (error) {
      console.error('Error creating staff:', error);
    }
  };

  const handleStaffDelete = async (id) => {
    await axios.delete(`${API_URL}/api/staffs/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
    });
    fetchStaff();
  };

  return (
    < >
      {currentLanguage === 'English' && (

        <div className='sub-container'>


          {/* <div className="thumbnail-url-converter">
                        {isClipboardCopied && <p style={{
                            color: 'green',
                            position: 'absolute', top: '10px', right: '20px', backgroundColor: 'lightgrey'
                        }}>Copied to clipboard!</p>}
                        <p>Google Drive Thumbnail URL Converter</p>
                                              <div>
                            <input
                                type="text"
                                placeholder="Enter original Google Drive URL"
                                value={originalUrl}
                                onChange={(e) => setOriginalUrl(e.target.value)}
                            />
                           
                            <button onClick={convertUrl}>Convert URL</button>
                            
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder="Converted thumbnail URL"
                                value={convertedUrl}
                                readOnly
                            />
                          
                            <button onClick={copyToClipboard}>
                                <FaCopy style={{ marginRight: '5px' }} /> Copy URL
                            </button>
                        </div>
                    </div> */}
          <div className='admin-content-form'>

            <form onSubmit={handleStaffSubmit}>
              <h3>Manage Staff</h3>

              <input
                type="text"
                name="image"
                placeholder="Image URL"
                value={newStaff.image}
                onChange={handleStaffChange}
              />
              <input
                type="text"
                name="name"
                placeholder="Staff Name"
                value={newStaff.name}
                onChange={handleStaffChange}
              />
              <input
                type="text"
                name="role"
                placeholder="Role/Position"
                value={newStaff.role}
                onChange={handleStaffChange}
              />
              <textarea
                name="description"
                placeholder="Role Description"
                value={newStaff.description}
                onChange={handleStaffChange}
              ></textarea>
              <button type="submit">Add staff member</button>
            </form>
            <div className='admin-cloudinary-container'>
              <UploadWidgetClaudinary folderName='staff'/>
            </div>
            {isLoading && <p>Loading...</p>}
            <h4>Staff (current)</h4>
            <table>

              <thead>
                <tr>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {staffs.map((staff) => (
                  <tr key={staff._id}>
                    <td><img src={staff.image} alt={staff.name} /></td>
                    <td>{staff.name}</td>
                    <td>{staff.role}</td>
                    <td>
                      <button onClick={() => handleStaffDelete(staff._id)}><FaTrash /> </button>
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>

          </div>







        </div>

      )}
      {currentLanguage === 'አማርኛ' && (
        <div className='sub-container'>

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <h1>መነሻ ገጽ</h1>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminStaff;