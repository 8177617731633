import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

import './featured-products.css'; // Import CSS file for styling
import { useAppContext } from './AppContext';


import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import introAd1 from './assets/images/ads/intro/introAdSample1.png'
import axios from 'axios';
import SvgFeaturedIcon from './svgComponents/SvgFeaturedIcon.tsx';
import SvgLoading from './svgComponents/SvgLoading.tsx';

import prod1 from './assets/images/ads/products/1.png';

import prod3 from './assets/images/ads/products/3.png';
import prod4 from './assets/images/ads/products/4.png';
import prod5 from './assets/images/ads/products/5.png';

// const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
const API_URL = 'https://api-netconn.brosfe.com';
const FETCH_DB = process.env.REACT_APP_FETCH_DB === 'true';

const FeaturedProducts = () => {
    const { currentLanguage } = useAppContext();
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentFeaturedIndex, setCurrentFeaturedIndex] = useState(0);
    const [animatedFeaturedIndex, setAnimatedFeaturedIndex] = useState(currentFeaturedIndex);
    const [renderKey, setRenderKey] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const [previousButtonPressed, setPreviousButtonPressed] = useState(false);



    const isInView = useRef(false);


    const isInViewFeaturedProductsRef = useRef(null);

    const localResults = [
        {
            description: "High speed TP-Link Router.",
            image: prod1,
            name: "TP-Link Router",
            price: {
                past: [{ price: "$20" }],
                current: "$15"
            }
        },
       
        {
            description: "Automation Siemens Logic Controller.",
            image: prod3,
            name: "Siemens Logic Controller",
            price: {
                past: [{ price: "$40" }],
                current: "$35"
            }
        },
        {
            description: "Laserjet printer colour.",
            image: prod4,
            name: "LaserJet Printer",
            price: {
                past: [{ price: "$40" }],
                current: "$35"
            }
        },
        {
            description: "Attendance controller biometric.",
            image: prod5,
            name: "Biometric Register",
            price: {
                past: [{ price: "$40" }],
                current: "$35"
            }
        }
    ];
    

    

    // const intervalIdRef = useRef(null);
    // const isInViewRef = useRef(false)

    useEffect(() => {
        if(FETCH_DB){
            fetchFeaturedProducts();
        }else{
            setSearchResults(localResults);
        }
        
    }, []);

    const fetchFeaturedProducts = async () => {
        console.log("fetching featured products");
        try {
            setIsLoading(true);
            const response = await axios.get(`${API_URL}/api/products`, {
                params: {
                    isFeatured: true,
                },
            });
            setSearchResults(response.data);
            console.log("search results", response.data);
        } catch (error) {
            setError('Error fetching featured products');
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {

        setAnimatedFeaturedIndex(currentFeaturedIndex);
        setRenderKey((prevKey) => prevKey + 1);

    }, [currentFeaturedIndex]);



    useEffect(() => {
        const startInterval = () => {
            return setInterval(() => {
                setCurrentFeaturedIndex((prevIndex) =>
                    prevIndex === searchResults.length - 1 ? 0 : prevIndex + 1
                );
            }, 5000);
        };

        let interval = startInterval();
        setIntervalId(interval);

        const handleVisibilityChange = () => {
            if (isInViewFeaturedProductsRef.current) {
                if (!interval) {
                    interval = startInterval();
                }
            } else {
                if (interval) {
                    clearInterval(interval);
                    interval = null;
                }
            }
        };

        isInView.current = isInViewFeaturedProductsRef.current;
        handleVisibilityChange();

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    isInView.current = true;
                } else {
                    isInView.current = false;
                }
                handleVisibilityChange();
            });
        });

        if (isInViewFeaturedProductsRef.current) {
            observer.observe(isInViewFeaturedProductsRef.current);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
            if (isInViewFeaturedProductsRef.current) {
                observer.unobserve(isInViewFeaturedProductsRef.current);
            }
        };
    }, [searchResults]);

    // useEffect(() => {
    //     const startInterval = () => {
    //         return setInterval(() => {
    //             setCurrentFeaturedIndex((prevIndex) =>
    //                 prevIndex === searchResults.length - 1 ? 0 : prevIndex + 1
    //             );
    //         }, 5000);
    //     };

    //     const clearExistingInterval = () => {
    //         if (intervalIdRef.current) {
    //             clearInterval(intervalIdRef.current);
    //             intervalIdRef.current = null;
    //         }
    //     };

    //     const handleVisibilityChange = (isVisible) => {
    //         if (isVisible) {
    //             if (!intervalIdRef.current) {
    //                 intervalIdRef.current = startInterval();
    //             }
    //         } else {
    //             clearExistingInterval();
    //         }
    //     };

    //     const observer = new IntersectionObserver((entries) => {
    //         entries.forEach((entry) => {
    //             isInViewRef.current = entry.isIntersecting;
    //             handleVisibilityChange(entry.isIntersecting);
    //         });
    //     });

    //     const observedElement = isInViewFeaturedProductsRef.current;
    //     if (observedElement) {
    //         observer.observe(observedElement);
    //     }

    //     return () => {
    //         clearExistingInterval();
    //         if (observedElement) {
    //             observer.unobserve(observedElement);
    //         }
    //     };
    // }, [searchResults, isInViewFeaturedProductsRef]);
    
    const handlePrevious = () => {
        setPreviousButtonPressed(true);
        clearInterval(intervalId);
        setCurrentFeaturedIndex((prevIndex) =>
            prevIndex === 0 ? searchResults.length - 1 : prevIndex - 1
        );
        setIntervalId(setInterval(() => {
            setCurrentFeaturedIndex((prevIndex) =>
                prevIndex === searchResults.length - 1 ? 0 : prevIndex + 1
            );
        }, 5000));
    };

    const handleNext = () => {
        // setPreviousButtonPressed(false);
        clearInterval(intervalId);
        setCurrentFeaturedIndex((prevIndex) =>
            prevIndex === searchResults.length - 1 ? 0 : prevIndex + 1
        );
        setIntervalId(setInterval(() => {
            setCurrentFeaturedIndex((prevIndex) =>
                prevIndex === searchResults.length - 1 ? 0 : prevIndex + 1
            );
        }, 5000));
    };



    return (
        <motion.div
            initial={{ opacity: 0 }} // Initial position before movement animation
            animate={{ opacity: 1, }} // Move to the left corner of the screen
            transition={{ duration: 2, ease: 'linear' }} // Delay movement animation by 1 second
            className='sub-container'

        >
            {currentLanguage === 'English' && (
                <div className='featured-products-container'
                    ref={isInViewFeaturedProductsRef}>
                    <h2 style={{ zIndex: 2 }}>FEATURED PRODUCTS</h2>

                    {searchResults.length > 0 && (
                        <div className="featured-products">
                            <div onClick={handlePrevious} className="featured-product-button">
                                <FaArrowLeft />
                            </div>
                            <div className="featured-product-info">
                                <h4>{searchResults[animatedFeaturedIndex].description}</h4>
                             

                                 <Link to={'/contactus'}>
                                    <button>Order Now</button>
                                </Link>
                            </div>

                            <AnimatePresence initial={false} mode='wait'>
                                <motion.div

                                    className="featured-product-item"
                                    key={renderKey}
                                >
                                    <SvgFeaturedIcon />
                                    <motion.div
                                        className='featured-product-image'

                                        initial={previousButtonPressed ? { opacity: 0, x: 50 } : { opacity: 0, x: -50 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        exit={previousButtonPressed ? { opacity: 0, x: -50 } : { opacity: 0, x: 50 }}
                                        transition={{ duration: 0.5 }}
                                        onAnimationComplete={() => setTimeout(() => setPreviousButtonPressed(false), 1000)}
                                    >
                                        <img src={searchResults[animatedFeaturedIndex].image} alt="Product" />

                                    </motion.div>

                                    <motion.div className="featured-product-detail"

                                        initial={{ opacity: 0, }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0, }}
                                        transition={{ duration: 1 }}>
                                        <h4>{searchResults[animatedFeaturedIndex].name}</h4>
                                        {/* <div className="featured-product-price">
                                            <p className="price-was">{searchResults[animatedFeaturedIndex].price.past[0].price}</p>
                                            <p className="price-now">{searchResults[animatedFeaturedIndex].price.current}</p>
                                        </div> */}
                                    </motion.div>
                                </motion.div>
                            </AnimatePresence>

                            <div onClick={handleNext} className="featured-product-button">
                                <FaArrowRight />
                            </div>
                        </div>
                    )}

                    {isLoading &&

                        <div className="loader">

                            <SvgLoading />
                        </div>
                    }

                </div>
            )}
            {currentLanguage === 'አማርኛ' && (
                <div className='sub-container'>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h1>መነሻ ገጽ</h1>
                    </div>
                </div>
            )}
        </motion.div>
    );
};

export default FeaturedProducts;