import React, { useEffect, useState } from 'react';
import './contact-point.css'; // Import CSS file for styling
import { useAppContext } from './AppContext';
import AppDropdown from './AppDropdown';
import { FaHandPointRight, FaPhone } from 'react-icons/fa';
import styled from 'styled-components';

import SvgLoading from './svgComponents/SvgLoading.tsx';

import axios from 'axios';

// const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
const API_URL = 'https://api-netconn.brosfe.com';


const ContactPoint = ({backgroundColor, header, description}) => {
  const { currentLanguage } = useAppContext();
  const [selectedApp, setSelectedApp] = useState('');
  const [messageSuccess, setMessageSuccess] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [messageProcessing, setMessageProcessing] = useState(false);


  // CONTACTS
  const telegramAccount = process.env.REACT_APP_TELEGRAM_ACCOUNT;
  const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
  const phoneNumber = process.env.REACT_APP_PHONE_NUMBER;

  console.log('phoneNumber', phoneNumber);

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    productDetails: ''
  });


 const MessageToClient = ()=>{
  return(

    messageSuccess ? (
      <p>Message received successfully! We'll get back to you shortly.</p>

    ): messageError ? (
      <p>Oops! Something went wrong. Please try again later.</p>

    ): null
    
  
  )
 }

  

  // Using fetch
  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessageProcessing(true);
  
    try {
      await axios.post(`${API_URL}/api/email/send-email`, formData);
      setMessageProcessing(false);
      setMessageSuccess(true);
      // Reset form or show success message
      setFormData({
        name: '',
        phone: '',
        email: '',
        productDetails: ''
      });
    } catch (err) {
      console.error(err);
      setMessageProcessing(false);
      setMessageError(true);
    }
  };

  const EllipticButton = styled.button`
  background-color: white;
  color: green;
  border: none;
  border-radius: 0.6rem;
  
  font-size: 1rem;
  cursor: pointer;
  margin-left: 0.5rem;

  &:hover {
    background-color: #5733FF;
    color: #fff;
  }
`

  const handleClick = () => {
    switch (selectedApp) {
      case 'call':
        window.location.href = `tel:${phoneNumber}`;
        break;
      case 'whatsapp':
        window.open(`https://api.whatsapp.com/send?phone=${whatsappNumber}`, '_blank');
        break;
      case 'telegram':
        window.open(`https://t.me/${telegramAccount}`, '_blank');
        
        break;
      default:
        console.log('No app selected');
    }
  };

  useEffect(() => {
    if (messageSuccess || messageError) {
      setTimeout(() => {
        setMessageSuccess(false);
        setMessageError(false);
      }, 5000);
    }
  }, [messageSuccess, messageError]);

  const handleAppSelect = (app) => {
    setSelectedApp(app);
  };



  return (
    <div className='sub-container'
      style={{ backgroundColor}}>
      {currentLanguage === 'English' && (


        <div style={{ width: '70%', margin: 'auto' }}>


          <h2 style={{ textAlign: 'center', width: '100%' }}>{header}</h2>
          <h4 style={{ textAlign: 'center', width: '100%' }}>{description}</h4>
          <form onSubmit={handleSubmit}>
            <div className='form-input-container'>
              <input
                type="text"
                placeholder="Name (personal or company)"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              />
              <input
                type="tel"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              />
              <input
                type="email"
                placeholder="Email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              />
            </div>
            <div className='form-textarea-container'>
              <textarea
                placeholder="Please write your enquiry here."
                value={formData.productDetails}
                onChange={(e) => setFormData({ ...formData, productDetails: e.target.value })}
              />
              <button type="submit">Send Now</button>
            </div>
            {(messageSuccess || messageError) &&( <div className='form-status'
            >
              <MessageToClient />
            </div>)}

            {messageProcessing && ( <div className='form-processing'
            >
            <SvgLoading />
            </div>)}
              
          </form>
          <div className='quick-contact-point'>
            <div className='pointers'>
              <p>Reach Us Right Now </p>
              <FaHandPointRight />
            </div>
            <div>
              <AppDropdown onAppSelect={handleAppSelect} />
              <EllipticButton onClick={handleClick}>

                <FaPhone /></EllipticButton>

            </div>


          </div>
        </div>


      )}
      {currentLanguage === 'አማርኛ' && (
        <>

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <h1>መነሻ ገጽ</h1>
          </div>
        </>
      )}
    </div>
  );
};

export default ContactPoint;