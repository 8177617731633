import React, { useEffect } from 'react';
import NavigationBar from './NavigationBar';
import { useAppContext } from './AppContext';
import { useNavigate } from 'react-router-dom';

import CartItem from './CartItem';
import './cart.css';

function Cart() {

  const { cartItems, removeFromCart, updateQuantity } = useAppContext();
  const navigate = useNavigate();

  const handleProceedToCheckout = () => {
    navigate('/checkout');
  };

  const { currentLanguage, setPage } = useAppContext();
  useEffect(() => {
    setPage('Cart');
  }, []);

  const groupedCartItems = cartItems.reduce((acc, item) => {
    console.log('Current item:', item);
    const existingItem = acc.find((i) => i.id === item.id);
    if (existingItem) {
      existingItem.quantity += item.quantity;
    } else {
      acc.push({ ...item, quantity: item.quantity });
    }
    return acc;
  }, []);

  const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

  return (
    <div className="main-container">
      {currentLanguage === 'English' && (
        <div className="sub-container">
          <NavigationBar />
          <CartItem cartItems={cartItems} />
          <div className='cart-details'>
            <h2>Cart</h2>
            {cartItems.length === 0 ? (
              <p>Your cart is empty.</p>
            ) : (
              // <div className="cart-items-container">
              //   {cartItems.map((item, index) => (
              //     <div key={index}>
              //       <img src={item.image} alt={item.name} />
              //       <p>{item.name}</p>
              //       <p>Price: ETB{item.price}</p>
              //       <div>
              //         <button onClick={() => {
              //           if (item.quantity > 0) {
              //             updateQuantity(index, item.quantity - 1);
              //           }
              //         }}
              //           disabled={item.quantity <= 0}>-</button>
              //         <p>Quantity: {item.quantity} </p>
              //         <button onClick={() => updateQuantity(index, item.quantity + 1)}>+</button>
              //       </div>
              //       <button onClick={() => removeFromCart(index)}>Remove</button>
              //     </div>
              //   ))}
              //   <p>Total: ETB{totalPrice.toFixed(2)}</p>
              //   <div className='button-cta-cart-container'>
              //     <button onClick={handleProceedToCheckout}>Proceed to Checkout</button>
              //   </div>
              // </div>
              <div className="cart-items-container">
                {groupedCartItems.map((item, index) => (
                  <div key={index}>
                    <img src={item.image} alt={item.name} />
                    <p>{item.name}</p>
                    <p>Price: {item.price}ETB</p>
                    <div>
                      <button
                        onClick={() => {
                          if (item.quantity > 1) {
                            updateQuantity(index, item.quantity - 1);
                          }
                        }}
                        disabled={item.quantity <= 1}
                      >
                        -
                      </button>
                      <p>Quantity: {item.quantity}</p>
                      <button onClick={() => updateQuantity(index, item.quantity + 1)}>
                        +
                      </button>
                    </div>
                    <button onClick={() => removeFromCart(index)}>Remove</button>
                    <p>Item Total: ETB{(item.price * item.quantity).toFixed(2)}</p>
                  </div>
                ))}
                <p>Total: ETB{totalPrice.toFixed(2)}</p>
                <div className="button-cta-cart-container">
                  <button onClick={handleProceedToCheckout}>Proceed to Checkout</button>
                </div>
              </div>

            )}




          </div>
        </div>
      )}
      {currentLanguage === 'አማርኛ' && (
        <div className="sub-container">
          <NavigationBar />
          <div className="Motto">
            <h1>ይህ የጋሪዎ ገጽ ነው፡፡</h1>
          </div>
        </div>
      )}
    </div>
  );
}

export default Cart;