import React from 'react';
import './payment-telebirr.css'; // Import CSS file for styling
import { useAppContext } from './AppContext';

import telebirr from './assets/images/payment/telebirr.png'
import axios from 'axios'; // Import axios for making API requests

// const API_URL = process.env.REACT_APP_API_URL || '';
const API_URL = 'https://api-netconn.brosfe.com';

const PaymentTelebirr = () => {
    const { currentLanguage } = useAppContext();

    const handleTelebirrClick = async () => {
        try {
          // Assuming you have the order title and amount available
          const orderTitle = 'Example Order';
          const orderAmount = 100;
    
          // Make an API request to your server
          const response = await axios.post(`${API_URL}/api/telebirr/payment`, {
            title: orderTitle,
            amount: orderAmount,
          });
    
          // Handle the successful response
          const rawRequest = response.data;
          console.log('Raw Request:', rawRequest);
    
          // You can either:
          // 1. Redirect the user to telebirr's payment page
          // window.location.href = 'https://telebirr.com/payment?rawRequest=' + encodeURIComponent(rawRequest);
    
          // 2. Display the raw request to the user with instructions
          alert(`Please complete the payment using telebirr's app or website with the following raw request:\n\n${rawRequest}`);
        } catch (error) {
          console.error('Error during telebirr payment:', error);
          alert('An error occurred during the telebirr payment process. Please try again later.');
        }
      };
    

    return (
        <div style={{width:'20%', margin:'auto',
        }}>
            {currentLanguage === 'English' && (

               
                  
                    
                    <div className='payment-container'>

<img src={telebirr} alt='telebirr' onClick={handleTelebirrClick} />

                       
                    </div>
                  
              

            )}
            {currentLanguage === 'አማርኛ' && (
                <div className='sub-container'>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h1>መነሻ ገጽ</h1>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PaymentTelebirr;