import React, { useEffect, useState } from 'react';
import './admin-testimony.css'; // Import CSS file for styling
import { useAppContext } from './AppContext';

import { FaEye, FaEyeSlash, FaCopy, FaTrash } from 'react-icons/fa';
import axios from 'axios';
import AdminTestimony from './AdminTestimony';
import AdminClientList from './AdminClientList';


const AdminOthers = () => {
    const { currentLanguage } = useAppContext();
    
   
    return (
        < >
            {currentLanguage === 'English' && (

                <div className='sub-container'>

                                     
                   <AdminTestimony />
                   <AdminClientList />







                </div>

            )}
            {currentLanguage === 'አማርኛ' && (
                <div className='sub-container'>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h1>መነሻ ገጽ</h1>
                    </div>
                </div>
            )}
        </>
    );
};

export default AdminOthers;