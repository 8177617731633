import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import LandingPage from './LandingPage';
import NavigationLanguage from './NavigationLanguage';
import Products from './Products';
import Home from './Home';
import ExploreMore from './ExploreMore';
import Cube from './CubeIntro';
import NavigationBar from './NavigationBar';
import { useAppContext } from './AppContext';
import './scroll_reveal.css'; // Import CSS file for styling
import { useNavigate } from 'react-router-dom';


const ScrollReveal = () => {
  const { currentLanguage, setPage } = useAppContext();
  const navigate = useNavigate();
  const [exploreMore, setExploreMore] = useState(false);
  useEffect(() => {
    setPage('LandingPage');
  }, []);
  const [reveal, setReveal] = useState(false);
  const [scrollEnd, setScrollEnd] = useState(false);

  const handleMouseClick = () => {
    setReveal(true);
    setScrollEnd(true);
    navigate('/home');
    document.body.style.overflow = 'auto'; // Allow scrolling
    
  };

  useEffect(() => {
    setTimeout(() => {
      // setReveal(true);
      // setScrollEnd(true);
      
      navigate('/home');
    }, 3500);
   
  }, []);

  const handleScrollEndChange = (newValue) => {
    setScrollEnd(newValue);
    if (newValue) {
      document.body.style.overflow = 'auto'; // Allow scrolling
    }

  };


  return (
    <div className="scroll-reveal-container" onClick={handleMouseClick}>
      {/* Landing Page */}
      <motion.div
        className="landing-page-container"
        initial={{ opacity: reveal ? 0 : 1 }}
        animate={{ opacity: reveal ? 0 : 1 }}
        transition={{ duration: 1 }}
      >
        <LandingPage />
      </motion.div>

      {/* {reveal && (
       
          <Home />
     

      )} */}

    


    </div>
  );
};

export default ScrollReveal;
