import React, { useState, useEffect } from 'react';
import NavigationBar from './NavigationBar';
import { useAppContext } from './AppContext';
import { motion } from 'framer-motion';
import './about.css';
import AboutPitch from './AboutPitch.js';
import CompanyStaff from './CompanyStaff';
import Footer from './Footer';
import CtaAbout from './CtaAbout';
import SvgAboutBgr from './svgComponents/SvgAboutBgr.tsx';




function About() {
    const { currentLanguage, setPage } = useAppContext();
    useEffect(() => {
        setPage('About');
    }, []);
    return (
        <motion.div
            initial={{ opacity: 0 }} // Initial position before movement animation
            animate={{ opacity: 1, }} // Move to the left corner of the screen
            transition={{ duration: 2, ease: 'linear' }} // Delay movement animation by 1 second
            style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}
        >
            {currentLanguage === 'English' && (
                <div className='main-container'>

                    <div className='sub-container'
                        style={{ width: '100%' }}>
                        <NavigationBar />
                        <div className='motto-container'>

                            <SvgAboutBgr />

                            <h1 style={{zIndex: 1}}>BUILDING STRONG, LASTING RELATIONSHIPS</h1>
                            <p style={{zIndex: 1}}>NetConn Engineering, established in 2011, is a dynamic provider of technology and excellence. 
                            We offer a comprehensive suite of IT and office automation solutions, driving innovation across diverse sectors. 
                            Our focus on client satisfaction and long-term partnerships drives us to go above and beyond in all our engagements, ensuring success for both our clients and our company.
                            </p>

                        </div>


                    </div>
                    <AboutPitch />
                  
                    <CompanyStaff />
                    <CtaAbout />
                    <Footer />
                </div>
            )}
            {currentLanguage === 'አማርኛ' && (
                <div className='sub-container'>
                    <NavigationBar />
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h1>መነሻ ገጽ</h1>
                    </div>
                </div>
            )}
        </motion.div>
    );
}

export default About;


