import * as React from "react"
import { SVGProps } from "react"
import { motion } from "framer-motion"
const SvgComputerMouse = (props: SVGProps<SVGSVGElement>) => (
  <motion.svg
    // width={272}
    // height={536}
    viewBox="0 0 272 536"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      width: '100%', height: '100%', overflow: 'visible',

    }}
  >
    <motion.g id="Group"


      initial={{ scale: 1, rotate: 0, y:0, x:0, opacity: 1 }} // Initial opacity and rotation

      animate={{ scale: 30, rotate: 90, y:-50, x:-125, opacity: 0.8 }}
      transition={{

        scale: { duration: 1.5, delay: 2 },
        rotate: { duration: 1.5, delay: 2 },
        y: { duration: 1.5, delay: 2 },
        x: { duration: 1.5, delay: 2 },
        opacity: { duration: 1.5, delay: 2 },

        repeat: 0,
        ease: "easeIn"
      }}
    >


      <motion.path
        id="Vector"
        d="M0.773438 403.847C0.773438 476.808 59.9148 536 132.893 536H139.072C212.067 536 271.225 476.808 271.225 403.847V336.763H0.773438V403.847Z"
        // fill="#020202"

        initial={{ y: 0, opacity: 0, fill:'#020202' }} // Initial opacity and rotation

        animate={{ y: [0, 50, 0], rotate: [0, 135, 0], opacity: 1 , fill:['#020202', '#020202','#7272fc63']}}
        transition={{

          y: { duration: 1, delay: 1 },
          opacity: { duration: 0.25, delay:0.5 },
          rotate: { duration: 1, delay: 1 },
          fill: { duration: 1.5, delay: 2 },
          repeat: 0,
          ease: "easeIn"
        }}
      />
      <motion.path
        id="Vector_2"
        d="M36.4564 46.1779C31.4524 56.6561 32.4767 67.89 39.5293 79.594C49.2183 95.7982 59.2935 96.6378 69.9732 97.4774C80.082 98.3002 91.4837 99.2574 109.098 112.725C137.427 134.47 133.682 173.377 131.415 186.693H0.773438V329.14H271.208V186.693H142.934C145.638 169.817 148.241 128.475 116 103.724C95.7489 88.2586 81.3749 87.0496 70.8967 86.21C60.9391 85.404 55.9519 85.001 49.2183 73.784C44.2143 65.388 43.3411 57.9827 46.6659 51.1148C54.5246 34.7258 86.6812 23.4752 158.702 11.8551V0C89.1496 10.8308 47.4048 23.3912 36.4564 46.1779ZM136.436 218.531C147.032 218.531 155.646 227.162 155.646 237.741V287.714C155.646 298.293 147.048 306.957 136.436 306.957H135.546C124.933 306.957 116.353 298.293 116.353 287.714V237.741C116.353 227.162 124.933 218.531 135.546 218.531H136.436Z"
        // fill="#020202"

        initial={{ y: -100, opacity: 0, fill:'#020202' }} // Initial opacity and rotation

        animate={{ y: 0, opacity: 1, fill:['#020202', '#020202','#F5F5F5'] }}
        transition={{

          y: { duration: 0.5, delay: 0.5 },
          opacity: { duration: 0.25, delay: 0.5 },
          fill: { duration: 1.5, delay: 2 },
          repeat: 0,
          ease: "easeIn"
        }}
      />

    </motion.g>
  </motion.svg>
)
export { SvgComputerMouse }
