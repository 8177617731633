import React, { useState, useEffect, useRef } from 'react';

const YearsOfExperience = () => {
  const [years, setYears] = useState(0); // Initialize years state
  const [isVisible, setIsVisible] = useState(false); // Track visibility of the component
  const [isVisibleTexts, setIsVisibleTexts] = useState(false);
  const yearsRef = useRef(null); // Reference to the years counter element

  useEffect(() => {
    // Calculate the difference in years from 2011 to the current date
    const startYear = 2011;
    const currentYear = new Date().getFullYear();
    const difference = currentYear - startYear;

    // Function to increment the years dynamically using setTimeout
    const incrementYears = (counter) => {
      if (counter <= difference) {
        setYears(counter);
        setTimeout(() => incrementYears(counter + 1), 50); // Increment after 50 milliseconds
      }

      if (counter === difference) {
        setIsVisibleTexts(true);
      }

      
    };

    // Intersection Observer callback function
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Set visibility to true when component is in view
        }
      });
    };

    // Create a new Intersection Observer
    const observer = new IntersectionObserver(handleIntersection);
    if (yearsRef.current) {
      observer.observe(yearsRef.current); // Observe the years counter element
    }

    // Start incrementing the years only when the component is in view
    if (isVisible) {
      setTimeout(() => incrementYears(0), 100);
      incrementYears(0);
    }

    // Cleanup function
    return () => {
      if (yearsRef.current) {
        observer.unobserve(yearsRef.current); // Stop observing the years counter element
      }
    };
  }, [isVisible]); // Run when isVisible state changes

  return (
    <div ref={yearsRef}
      style={{ display: 'flex', flexDirection: 'row', 
        width: '80%', 
       }}>
      {isVisible && (
        <div style={{ fontSize: '12rem', color: '#5733FF', width:'70%',
           
         }}>{years} 
         </div>
       
      )}

      {isVisibleTexts && <div style={{ display: 'flex', alignItems: 'flex-end', fontSize: '2rem', color: '#5733FF', width:'20%',
         textAlign:'left', position:'relative', left:'-10%',
        }}>+years </div>}
       
      
    </div>
  );
};

export default YearsOfExperience;
