import React, { useState, useEffect, Suspense, lazy } from 'react';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { motion } from 'framer-motion';
import './home.css'; // Import CSS file for styling
import { useAppContext } from './AppContext';
import NavigationBar from './NavigationBar';
import Testimonies from './Testimonies';
import ClientsAndCustomers from './ClientsAndCustomers';
import FeaturedProducts from './FeaturedProductsTemp.js';
import { useNavigate } from 'react-router-dom';
import ContactPoint from './ContactPoint';
import Map from './Map';
import CompanyPitchLazy from './CompanyPitchLazy.js';
import Footer from './Footer';
import axios from 'axios';
// import CompanyPitch from './CompanyPitch';



import { SvgComputerMouse } from './svgComponents/SvgComputerMouse.tsx';
import SvgTextMotto from './svgComponents/SvgTextMotto.tsx';
import YearsOfExperience from './YearsOfExperience.js';

gsap.registerPlugin(ScrollTrigger);
const CompanyPitch = lazy(() => import('./CompanyPitchOpt'));




const Home = () => {
    const navigate = useNavigate();
    const { currentLanguage, setPage } = useAppContext();

    async function getCoordinatesFromOSM(osmType, osmId) {
        let url;

        // Determine the API endpoint based on the OSM type
        if (osmType === 'N' || osmType === 'n') {
            // Node
            url = `https://api.openstreetmap.org/api/0.6/node/${osmId}`;
        } else if (osmType === 'W' || osmType === 'w') {
            // Way
            url = `https://api.openstreetmap.org/api/0.6/way/${osmId}`;
        } else if (osmType === 'R' || osmType === 'r') {
            // Relation
            url = `https://api.openstreetmap.org/api/0.6/relation/${osmId}`;
        } else {
            throw new Error('Invalid OSM type. Must be N, n, W, w, R, or r.');
        }

        try {
            // Make the API request
            const response = await axios.get(url);
            const xmlParser = new DOMParser();
            const xmlDoc = xmlParser.parseFromString(response.data, 'application/xml');

            // Extract the latitude and longitude based on the OSM type
            let latitude;
            let longitude;

            if (osmType === 'N' || osmType === 'n') {
                // For nodes, get the lat and lon attributes
                const node = xmlDoc.getElementsByTagName('node')[0];
                latitude = parseFloat(node.getAttribute('lat'));
                longitude = parseFloat(node.getAttribute('lon'));
            } else if (osmType === 'W' || osmType === 'w') {
                // For ways, get the coordinates of the first node (as an example)
                const nodes = xmlDoc.getElementsByTagName('nd');
                if (nodes.length > 0) {
                    const nodeId = nodes[0].getAttribute('ref');
                    // Make a request to get the node coordinates
                    const nodeResponse = await axios.get(`https://api.openstreetmap.org/api/0.6/node/${nodeId}`);
                    const nodeDoc = xmlParser.parseFromString(nodeResponse.data, 'application/xml');
                    const node = nodeDoc.getElementsByTagName('node')[0];
                    latitude = parseFloat(node.getAttribute('lat'));
                    longitude = parseFloat(node.getAttribute('lon'));
                }
            } else if (osmType === 'R' || osmType === 'r') {
                // For relations, find a way or node and use its coordinates
                const members = xmlDoc.getElementsByTagName('member');
                if (members.length > 0) {
                    const type = members[0].getAttribute('type');
                    const ref = members[0].getAttribute('ref');
                    // Make a request to get the coordinates of the first member
                    const memberResponse = await axios.get(`https://api.openstreetmap.org/api/0.6/${type}/${ref}`);
                    const memberDoc = xmlParser.parseFromString(memberResponse.data, 'application/xml');
                    const member = memberDoc.getElementsByTagName(type)[0];
                    latitude = parseFloat(member.getAttribute('lat'));
                    longitude = parseFloat(member.getAttribute('lon'));
                }
            }

            // Return the coordinates
            return { latitude, longitude };

        } catch (error) {
            console.error('Failed to fetch data from OpenStreetMap:', error);
            throw new Error('Unable to fetch coordinates from OpenStreetMap');
        }
    }

  
    const routeProducts = () => {
        navigate('/products-services');
    };
 


    const contactSettings = {
        backgroundColor: '#FFD9E8',
        header: 'Get In Touch',
        description: 'Tell us what you want and we\'ll reach out to you ASAP',


    }


    const [isCompanyPitchMounted, setIsCommpanyPitchMounted] = useState(false);

    useEffect(() => {
        setPage('Home');

        // const timer = setTimeout(() => {
        //     setIsCommpanyPitchMounted(true);
        // }, 2000);



        // return () => clearTimeout(timer);

        setIsCommpanyPitchMounted(true);
    }, []);


    return (
        <motion.div
            initial={{ opacity: 0 }} // Initial position before movement animation
            animate={{ opacity: 1, }} // Move to the left corner of the screen
            transition={{ duration: 2, ease: 'linear' }} // Delay movement animation by 1 second
            style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}
        >
            {currentLanguage === 'English' && (
                <div className='main-container'>
                    <NavigationBar />


                    <div className='sub-container'
                    >

                        <div className='motto-container'>


                            <SvgTextMotto />

                            <p>Welcome to Netconn Engineering, a dynamic leader in IT and Office Automation solutions. Since 2011, we've driven innovation, meeting industry demands with a comprehensive suite of services.</p>
                            {/* <button onClick={routeProducts}>
                                See Products
                            </button> */}
                        </div>

                    </div>

                    {isCompanyPitchMounted ? (
                        <Suspense fallback={<CompanyPitchLazy />}>
                            <CompanyPitch />
                        </Suspense>
                    ) : (
                        <CompanyPitchLazy />
                    )}

                    <FeaturedProducts />
                    <Testimonies />
                    <ClientsAndCustomers />
                    <ContactPoint backgroundColor={contactSettings.backgroundColor} header={contactSettings.header}
                        description={contactSettings.description} />

                    <Map />

                    <Footer />



                </div>
            )}
            {currentLanguage === 'አማርኛ' && (
                <div className='sub-container'>
                    <NavigationBar />
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h1>መነሻ ገጽ</h1>
                    </div>
                </div>
            )}
        </motion.div>
    );
};

export default Home;