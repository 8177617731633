import React from 'react';
import './company-pitch.css'; // Import CSS file for styling
import { useAppContext } from './AppContext.js';


import CustomWave from './CustomWave.js';



const CompanyPitchLazy = () => {
  const { currentLanguage} = useAppContext();


 

  
  return (
    <>
      {currentLanguage === 'English' && (
        <div className='sub-container' style={{ backgroundColor: '#fff', padding: '0px',
           
         }}>
          <CustomWave fillColor={'#5733FF'} waveHeight={'10rem'} />

          <div className="custom-container">

            <section className="custom-hero custom-pinned">
              <h1 
              style={{lineHeight:'0.5'}}
              ><span style={{color:'#5733FF'}}>INNOVATION <span style={{fontSize:'10rem'}}>.</span></span><br /><span style={{color:'#020202'}}><span style={{fontSize:'10rem'}}>.</span>SUCCESS</span></h1>
            </section>

          
          

          </div>
        

        </div>
      )}
      {currentLanguage === 'አማርኛ' && (
        <div className='custom-sub-container'>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <h1>መነሻ ገጽ</h1>
          </div>
        </div>
      )}
    </>
  );

};

export default CompanyPitchLazy;