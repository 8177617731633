import React, { useEffect, useState, useRef } from 'react';
import './clients-and-customers.css'; // Import CSS file for styling
import { useAppContext } from './AppContext';
import CustomWaveBottom from './CustomWaveBottom';
import CustomWave from './CustomWave';
// import { motion } from 'framer-motion';
import { motion, useAnimation, useInView } from 'framer-motion';
import SvgLoading from './svgComponents/SvgLoading.tsx';

import client1 from './assets/images/clients-customers/logos/ecx.jpeg';
import client2 from './assets/images/clients-customers/logos/cfc.jpeg';
import client3 from './assets/images/clients-customers/logos/giz.jpeg';
import client4 from './assets/images/clients-customers/logos/handm.png';
import client5 from './assets/images/clients-customers/logos/worldvision.png';
import client6 from './assets/images/clients-customers/logos/hyundai.jpeg';
import client7 from './assets/images/clients-customers/logos/jimmauniversity.jpeg';
import client8 from './assets/images/clients-customers/logos/niras.png';
import client9 from './assets/images/clients-customers/logos/wolaitasodo.jpeg';
import client10 from './assets/images/clients-customers/logos/ata.png';
import client11 from './assets/images/clients-customers/logos/cirdi.png';
import client12 from './assets/images/clients-customers/logos/icap.png';
import client13 from './assets/images/clients-customers/logos/mhengineering.jpeg';
import client14 from './assets/images/clients-customers/logos/vng.png';

import axios from 'axios';

// const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
const API_URL = 'https://api-netconn.brosfe.com';

const FETCH_DB = process.env.REACT_APP_FETCH_DB === 'true';

const ClientsAndCustomers = () => {
    const { currentLanguage } = useAppContext();
    const controls = useAnimation();
    const clientContainerRef = useRef(null);
    const isInView = useInView(clientContainerRef);

    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const localResults = [
        {
            _id: "1",
            image: client1,
            name: "Client 1"
        },
        {
            _id: "2",
            image: client2,
            name: "Client 2"
        },
        {
            _id: "3",
            image: client3,
            name: "Client 3"
        },
        {
            _id: "4",
            image: client4,
            name: "Client 4"
        },
        {
            _id: "5",
            image: client5,
            name: "Client 5"
        },
        {
            _id: "6",
            image: client6,
            name: "Client 6"
        },
        {
            _id: "7",
            image: client7,
            name: "Client 7"
        },
        {
            _id: "8",
            image: client8,
            name: "Client 8"
        },
        {
            _id: "9",
            image: client9,
            name: "Client 9"
        },
        {
            _id: "10",
            image: client10,
            name: "Client 10"
        },
        {
            _id: "11",
            image: client11,
            name: "Client 11"
        },
        {
            _id: "12",
            image: client12,
            name: "Client 12"
        },
        {
            _id: "13",
            image: client13,
            name: "Client 13"
        },
        {
            _id: "14",
            image: client14,
            name: "Client 14"
        }
    ];
    


    useEffect(() => {

        if(FETCH_DB){
            fetchClients();
        }else{
            setSearchResults(localResults);
        }
       
    }, []);

    const fetchClients = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${API_URL}/api/clients`);
            const trimmedData = response.data.slice(0, 12); // Trim the data to contain only the first 12 elements
        setSearchResults(trimmedData);
            // setSearchResults(response.data);
        } catch (error) {
            setError('Error fetching company staff');
        } finally {
            setIsLoading(false);
        }
    };
 


    useEffect(() => {
        if (isInView) {
          controls.start('visible');
        } else {
          controls.start('hidden');
        }
      }, [isInView, controls]);
    
      const fadeInOut = (index) => ({
        hidden: { opacity: 0.5 },
        visible: {
          opacity: 1,
          transition: {
            repeat: Infinity,
            repeatType: 'reverse',
            duration: 2, // Adjust duration as needed
            delay: index * 0.5, // Stagger the delay for each item
          },
        },
      });

    return (
        < >
            {currentLanguage === 'English' && (

                <div className='sub-container'>
                     <CustomWave fillColor='#020202' waveHeight={'9rem'} />

                    <motion.div className="client-logo-grid"
                    ref={clientContainerRef}
                      >
                        <div className="text-container">
                            <h3>Some of Our Clients and Customers</h3>
                            
                        </div>
                     
                        {isLoading && 
                        ( <div className='loader-clients'
                        >
                        <SvgLoading />
                        </div>)
                        }
                        

                        {searchResults && (
                            searchResults.map((client, index) => (

                                <motion.div className={`client-logo-container ${client.position}`} key={client._id}
                                variants={fadeInOut(index)}
            initial="hidden"
            animate="visible"
                                >
                                    <img src={client.image} alt={client.name} className="logo" />
                                </motion.div>

                            ))
                        )}
                    </motion.div>
                    <CustomWaveBottom fillColor='#FFD9E8' waveHeight={'9rem'}/>
                </div>

            )}
            {currentLanguage === 'አማርኛ' && (
                <div className='sub-container'>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h1>መነሻ ገጽ</h1>
                    </div>
                </div>
            )}
        </>
    );
};

export default ClientsAndCustomers;