import React, { useEffect, useState } from 'react';
import './company-staff.css'; // Import CSS file for styling
import { useAppContext } from './AppContext';
import axios from 'axios';
import CustomWaveBottom from './CustomWaveBottom.js';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import SvgLoading from './svgComponents/SvgLoading.tsx';

import staff1 from './assets/images/team/staff1.png';
import staff2 from './assets/images/team/staff2.png';
import staff3 from './assets/images/team/staff3.png';
import staff4 from './assets/images/team/staff4.png';

// const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
const API_URL = 'https://api-netconn.brosfe.com';
const FETCH_DB = process.env.REACT_APP_FETCH_DB === 'true';
const CompanyStaff = () => {
  const { currentLanguage } = useAppContext();
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const localResults = [
    {
      image: staff1,
      name: "Abraham Tsegaye",
      role: "CEO",

    },
    {
      image: staff2,
      name: "Arone Tsegaye",
      role: "Operations Manager",

    },
    {
      image: staff3,
      name: "Tsegereda ",
      role: "Sales",

    },
    {
      image: staff4,
      name: "Abel",
      role: "Software",

    }
  ];

  useEffect(() => {
    if(FETCH_DB){
      fetchStaff();
    }
    else{
      setSearchResults(localResults);
    }
    
  }, []);

  const fetchStaff = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_URL}/api/staffs`);
      setSearchResults(response.data);
    } catch (error) {
      setError('Error fetching company staff');
    } finally {
      setIsLoading(false);
    }
  };

  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, scale: 1, y: 0 });
    } else {
      controls.start({ opacity: 0, scale: 0.8, y: 100 });
    }
  }, [controls, inView]);

  return (
    < >
      {currentLanguage === 'English' && (

        <motion.div className='sub-container'
          style={{ backgroundColor: '#eee' }}

        >

          <h2>MEET OUR EXCEPTIONAL TEAM</h2>
          <h4>Driving Excellence, Nurturing Talent</h4>

          <motion.div className='staff-container'
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={controls}
            transition={{ duration: 1, ease: 'easeOut' }}

          >

            {searchResults && (
              searchResults.map((staff) => (

                <div className="staff-member" key={staff._id}>
                  <img src={staff.image} alt={staff.name} />
                  <h4>{staff.name}</h4>
                  <p>{staff.role}</p>
                </div>

              ))
            )}

            {isLoading &&
              (<div className='loader-staff'
              >
                <SvgLoading />
              </div>)
            }


          </motion.div>
          <CustomWaveBottom fillColor={'#FFD9E8'} waveHeight={'7.5rem'} />
        </motion.div>

      )}
      {currentLanguage === 'አማርኛ' && (
        <div className='sub-container'>

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <h1>መነሻ ገጽ</h1>
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyStaff;