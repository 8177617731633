import React, {useEffect} from 'react';
import './cta-about.css'; // Import CSS file for styling
import { useAppContext } from './AppContext';
import {motion, useAnimation} from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import ctaImage from './assets/images/image-collages/cta-about.png';

import CustomWaveBottom from './CustomWaveBottom.js';

const CtaAbout = () => {
    const { currentLanguage } = useAppContext();
    
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
      if (inView) {
          controls.start({ opacity: 1, scale: 1, y: 0 });
      } else {
          controls.start({ opacity: 0, scale: 0.8, y: 100 });
      }
  }, [controls, inView]);

    return (
        < >
            {currentLanguage === 'English' && (

                <div className='sub-container'
                style={{width: '100%', backgroundColor: '#FFD9E8'}}>

                    <h2 style={{width:'90%', margin:'auto',
                        textAlign: 'center', wordBreak: 'break-word',
                    }}>EMBARK ON A TRANSFORMATIVE JOURNEY WITH NETCONN ENGINEERING</h2>
                    <motion.div className='cta-about'
                     ref={ref}
                     initial={{ opacity: 0, y: 50 }}
                     animate={controls}
                     transition={{ duration: 1, ease: 'easeOut' }}>

                        <div className="cta-about-sub">
                            <p>Ready to elevate your business with the power of technology? Join us at NetConn Engineering and begin a transformative journey toward innovation and excellence. Find tailored solutions, seamless integration, and a commitment to driving your success in the digital era. Let's shape the future together, where your aspirations meet technological possibilities. Contact us today to unlock the potential of tomorrow!</p>
                            <img src={ctaImage} alt="business handshake" />
                        </div>
                        <div>
                            <button>Get In Touch</button>
                        </div>

                       
                    </motion.div>
                    <CustomWaveBottom fillColor={'#FFFFFF'} waveHeight={'7.5rem'} />
                    
                </div>

            )}
            {currentLanguage === 'አማርኛ' && (
                <div className='sub-container'>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h1>መነሻ ገጽ</h1>
                    </div>
                </div>
            )}
        </>
    );
};

export default CtaAbout;