import * as React from "react"
import { SVGProps } from "react"
import { delay, easeIn, motion } from "framer-motion"
const SvgFeaturedIcon = (props: SVGProps<SVGSVGElement>) => {

    

      const iconVariants = (index: number) => ({
        hidden: {
          opacity: 0,

        //   scale: 1.5,
          stroke:'#5733FF',
          fill: '#5733FF',
        },
        visible: {
            opacity: 1,
            stroke: ['#5733FF', '#020202', ], 
            fill: ['#5733FF', '#020202',], 
            // scale:1.5,
       length: 1,
          transition: {
            duration: 1,
            ease: 'easeInOut',
            delay: index * 0.25,
            repeat: Infinity,
            repeatType: 'reverse',
            repeatDelay: 0, // Add a delay between color changes
          },
        },
      });
    return(
  <svg
    id="visual"
    viewBox="0 0 960 600"
    // width={960}
    // height={600}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{ position:'absolute', top:'-20%', left:'10%',
 width:'100%', height:'50%',
    display:'flex', zIndex:1,
    //  backgroundColor:'#FFD9E8'
     }}
  >
    {/* <rect x={0} y={0} width={960} height={600} fill="none" /> */}
    <motion.g fill="none" stroke="#eee" strokeWidth={3}
    style={{ position:'absolute', top:'0%', left:'0%',
        
     }}>
      <motion.path
        d="M32.9 19L32.9 84.9L-32.9 84.9L-32.9 19L-89.9 -13.9L-57 -70.9L0 -38L57 -70.9L89.9 -13.9Z"
        transform="translate(702 392)"
        style={{ strokeWidth:3 }}
        variants={iconVariants(1)}
        initial="hidden"
        animate="visible"        
      />
     
    </motion.g>
  </svg>
)
}
export default SvgFeaturedIcon
