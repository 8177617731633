import React, { useEffect, useRef } from 'react';
import KUTE from 'kute.js';
import { useInView } from 'react-intersection-observer';
import './svg-morph.css'; // Import CSS file for styling

const SvgMorphMain = ( {svgType} ) => {
  const svgPaths = {
  
    0: {
      path1: {
        d:"M165.7 -163.2C228.2 -103.2 301.6 -51.6 298.2 -3.4C294.8 44.8 214.6 89.6 152.1 137.4C89.6 185.2 44.8 236.1 -4.2 240.4C-53.3 244.6 -106.5 202.2 -158.9 154.4C-211.2 106.5 -262.6 53.3 -258.1 4.5C-253.5 -44.2 -193.1 -88.4 -140.7 -148.4C-88.4 -208.4 -44.2 -284.2 3.7 -287.9C51.6 -291.6 103.2 -223.2 165.7 -163.2",
        transform: "translate(430.0307638476969 323.6780014582456)",
        fill: "#5733FF",
      },
      path2: {
        d:"M68.6 -55.9C115.1 -22.1 197.1 -11.1 235.7 38.7C274.4 88.4 269.8 176.8 223.3 204.4C176.8 232.1 88.4 199.1 8.5 190.6C-71.4 182.1 -142.8 198.2 -165.3 170.5C-187.8 142.8 -161.4 71.4 -159.5 1.9C-157.6 -67.6 -180.3 -135.3 -157.8 -169.1C-135.3 -203 -67.6 -203 -28.3 -174.7C11.1 -146.4 22.1 -89.8 68.6 -55.9" ,
        transform:"translate(406.281246793601 290.0037942873796)",
        fill: "#5733FF",

      }
     
    },
    1: {
      path1: {
        d: "M112.5 -123.8C155.2 -98.3 205.6 -71.7 217 -34.2C228.4 3.2 200.8 51.6 169.3 89.8C137.8 128.1 102.4 156.1 63.1 168.1C23.8 180.1 -19.4 176 -52.2 157.8C-85 139.6 -107.5 107.3 -129.4 73C-151.3 38.6 -172.6 2.2 -183.1 -48.6C-193.6 -99.4 -193.2 -164.6 -160.9 -191.9C-128.6 -219.2 -64.3 -208.6 -14.7 -191.1C34.9 -173.6 69.8 -149.2 112.5 -123.8",
        transform:"translate(434.80059623384903 316.82455109094127)",
        fill: "#FFD9E8",
      },
      path2: {
        d: "M131.1 -140.7C180.3 -114.9 237.9 -83.5 255.4 -37.2C272.9 9.1 250.3 70.3 211.5 107.6C172.7 145 117.6 158.5 65.2 176.5C12.7 194.5 -37.3 217.1 -87.7 211.5C-138.1 205.9 -189 172.2 -201.1 127.6C-213.1 83 -186.3 27.4 -162.4 -14.8C-138.5 -57.1 -117.5 -86 -90.9 -115.8C-64.3 -145.6 -32.1 -176.3 4.4 -181.5C40.9 -186.8 81.8 -166.5 131.1 -140.7" ,
        transform:"translate(421.3283982335455 284.988711544894)",
        fill: "#FFD9E8",

      }     
    
    },
    // Add more SVG path data objects for other types
  };

  
  const blob1Ref = useRef(null);
  const blob2Ref = useRef(null);

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });
 

  useEffect(() => {

    console.log("in view", inView);

    if (inView) {
      console.log("in view set", inView);
    const blob1 = blob1Ref.current;
    const blob2 = blob2Ref.current;

  
    if (blob1 && blob2 && svgPaths[svgType]) {
     
      const tween = KUTE.fromTo(
        blob1,
        { path: svgPaths[svgType].path1.d },
        { path: svgPaths[svgType].path2.d },
        { repeat: 999, duration: 3000, yoyo: true }
      );

      tween.start();
    }
  }
  }, [svgType, inView]); 
 

  return (
   <div className='wave-svg-morph' ref={ref}>
      <svg id="visual" viewBox="0 0 900 600" 
      xmlns="http://www.w3.org/2000/svg" version="1.1"
     
      >
        <rect x="0" y="0" width="900" height="600" fill="transparent"></rect>
        <g transform={svgPaths[svgType]?.path1?.transform}>
          <path id={`blob1-${svgType}`} ref={blob1Ref} d={svgPaths[svgType]?.path1?.d}
          fill={svgPaths[svgType]?.path1?.fill}></path>
        </g>
        <g style={{ visibility: 'hidden' }} transform={svgPaths[svgType]?.path2?.transform}>
          <path id={`blob2-${svgType}`} ref={blob2Ref} d={svgPaths[svgType]?.path2?.d} 
          fill={svgPaths[svgType]?.path2?.fill}></path>
        </g>
      </svg>
    </div>
  );
};

export default SvgMorphMain;
