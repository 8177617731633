import React, { useEffect, useState } from 'react';
import './admin-products.css'; // Import CSS file for styling
import { useAppContext } from './AppContext';

import { FaEye, FaEyeSlash, FaCopy, FaTrash } from 'react-icons/fa';
import axios from 'axios';

import UploadWidgetClaudinary from './UploadWidgetClaudinary';
// const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
const API_URL = 'https://api-netconn.brosfe.com';
const AdminBlogs = () => {
    const { currentLanguage } = useAppContext();
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [blogs, setBlogs] = useState([]);
    const [newBlog, setNewBlog] = useState({
        title: '',
        content: '',
        author: '',
        tags: [],
        comments: [],
        likes: 0,
        slug: '',
        status: 'draft',
        excerpt: '',
        featuredImage: ''
    });

    const [isClipboardCopied, setIsClipboardCopied] = useState(false);
    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            fetchBlogs();
        }
    }, []);

    const [originalUrl, setOriginalUrl] = useState('');
    const [convertedUrl, setConvertedUrl] = useState('');


    const convertUrl = () => {
        // Regular expression to extract the file ID from the original URL
        const fileIdMatch = originalUrl.match(/https:\/\/drive\.google\.com\/file\/d\/(.+?)\/view/);
        if (fileIdMatch) {
            const fileId = fileIdMatch[1];
            // Construct the converted URL in thumbnail format
            const thumbnailUrl = `https://drive.google.com/thumbnail?id=${fileId}`;
            setConvertedUrl(thumbnailUrl);
        } else {
            setConvertedUrl('Invalid URL');
        }
    };

    // Function to copy the converted URL to the clipboard
    const copyToClipboard = () => {
        navigator.clipboard.writeText(convertedUrl)
            .then(() => {
                // alert('Converted URL copied to clipboard!');
                setIsClipboardCopied(true);
            })
            .catch(() => {
                alert('Failed to copy the URL');
            });
    };

    useEffect(() => {
        if (isClipboardCopied) {
            setTimeout(() => {
                setIsClipboardCopied(false);
            }, 1000);
        }
    }, [isClipboardCopied]);
    
    const fetchBlogs = async () => {
        const response = await axios.get(`${API_URL}/api/blogs`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
        });
        setBlogs(response.data);
        setIsLoading(false);
    };
    const handleBlogChange = (e) => {
        const { name, value } = e.target;
        if (name === 'tags') {
            // If the field is 'tags', split the input value by commas
            setNewBlog({ ...newBlog, [name]: value.split(',') });
        } else {
            // Otherwise, directly update the state with the input value
            setNewBlog({ ...newBlog, [name]: value });
        }
    };
    

    const handleBlogSubmit = async (e) => {
        e.preventDefault();
        try {
            const authToken = localStorage.getItem('authToken');
            console.log('Auth Token:', authToken); // Add this line to log the token

            await axios.post('/api/blogs', newBlog, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            setNewBlog({
                title: '',
                content: '',
                author: '',
                tags: [],
                comments: [],
                likes: 0,
                slug: '',
                status: 'draft',
                excerpt: '',
                featuredImage: ''
            });
            fetchBlogs();
        } catch (error) {
            console.error('Error creating blog:', error);
        }
    };

    const handleBlogDelete = async (id) => {
        await axios.delete(`${API_URL}/api/blogs/${id}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
        });
        fetchBlogs();
    };

    const [isFeatureChecked, setIsFeatureChecked] = useState(false);

    const handleToggle = () => {
        setIsFeatureChecked(!isFeatureChecked);
        setNewBlog({ ...newBlog, isFeatured: !isFeatureChecked });
    };

    function truncateText(text, limit) {
        const words = text.split(' ');
        if (words.length > limit) {
            return words.slice(0, limit).join(' ') + '...';
        } else {
            return text;
        }
    }
    

    return (
        < >
            {currentLanguage === 'English' && (

                <div className='sub-container'>

                    <h2>Manage Blogs</h2>
                    <div className='admin-product-controller'>
                        <div className='admin-content-form'>

                            <form onSubmit={handleBlogSubmit}>


                                <input
                                    type="text"
                                    name="title"
                                    placeholder="Blog Title"
                                    value={newBlog.title}
                                    onChange={handleBlogChange}
                                />
                                <textarea
                                    name="content"
                                    placeholder="Blog Content"
                                    value={newBlog.content}
                                    onChange={handleBlogChange}
                                ></textarea>
                                <input
                                    type="text"
                                    name="author"
                                    placeholder="Author"
                                    value={newBlog.author}
                                    onChange={handleBlogChange}
                                />
                                <input
                                    type="text"
                                    name="tags"
                                    placeholder="Tags (comma-separated)"
                                    value={newBlog.tags.join(',')}
                                    onChange={(e) => setNewBlog({ ...newBlog, tags: e.target.value.split(',') })}
                                />
                               
                                <input
                                    type="text"
                                    name="slug"
                                    placeholder="Slug"
                                    value={newBlog.slug}
                                    onChange={handleBlogChange}
                                />
                                <select
                                    name="status"
                                    value={newBlog.status}
                                    onChange={handleBlogChange}
                                >
                                    <option value="draft">Draft</option>
                                    <option value="published">Published</option>
                                </select>
                                <input
                                    type="text"
                                    name="excerpt"
                                    placeholder="Excerpt"
                                    value={newBlog.excerpt}
                                    onChange={handleBlogChange}
                                />
                                <input
                                    type="text"
                                    name="featuredImage"
                                    placeholder="Featured Image URL"
                                    value={newBlog.featuredImage}
                                    onChange={handleBlogChange}
                                />


                                <button type="submit">Add Blog</button>
                            </form>



                        </div>
                        <div className='admin-cloudinary-container'>
                            <UploadWidgetClaudinary />
                        </div>
                    </div>

                    <div className='products-table'>
                        {isLoading && <p>Loading...</p>}
                        <table>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Content</th>
                                    <th>Author</th>
                                    <th>Tags</th>
                                    <th>Slug</th>
                                    <th>Status</th>
                                    <th>Excerpt</th>
                                    <th>Featured Image</th>
                                    <th>Actions</th>
                                   
                                </tr>

                            </thead>
                            <tbody>
                                {blogs.map((blog) => (
                                    <tr key={blog._id}>
                                       
                                        <td>{blog.title}</td>
                                        <td>{truncateText(blog.content, 20)}</td>
                                        <td>{blog.author}</td>
                                        <td>{blog.tags.join(', ')}</td>
                                        <td>{blog.slug}</td>
                                        <td>{blog.status}</td>
                                        <td>{truncateText(blog.excerpt, 20)}</td>
                                         <td><img src={blog.featuredImage} alt={blog.title} /></td>
                                        <td>
                                            <button onClick={() => handleBlogDelete(blog._id)}><FaTrash /> </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>





                </div>

            )}
            {currentLanguage === 'አማርኛ' && (
                <div className='sub-container'>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <h1>መነሻ ገጽ</h1>
                    </div>
                </div>
            )}
        </>
    );
};

export default AdminBlogs;